import dayjs from '../../services/wrappers/dayjs';

/**
 * This function takes a Date instance in the system's local time or an ISO8601 string,
 * and an IANA time zone name or offset string. It then formats this date in the target time zone regardless of the system's local time zone.
 * @param {Date} date
 * @param {string} timeZone
 * @param {string} [format]
 * @returns {string}
 */
export default function formatInTimeZone(date, timeZone, format) {
    return dayjs(date).tz(timeZone).format(format);
}
