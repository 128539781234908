import { strArrayToMap } from '../../../../utils/stations';
import { localisationService } from '@everon/jolt/services';

export default {
    updateTiles(state, countsByType) {
        Object.entries(countsByType).forEach(([type, count]) => {
            const tileToUpdate = state.tiles.find((tile) => tile.type === type);

            if (tileToUpdate) {
                tileToUpdate.count =
                    localisationService.getLocalisedNumber(count);
                tileToUpdate.isLoading = false;
            }
        });
    },

    setLoadingStateForTiles(state) {
        state.tiles.forEach((tile) => {
            tile.count = null;
            tile.isLoading = true;
        });
    },

    setCurrentUserProfile(state, sessionInfo) {
        const { permissions, ...rest } = sessionInfo;

        state.currentUserProfile = {
            ...rest,
            permissions: strArrayToMap(permissions),
        };
    },
};
