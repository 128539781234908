import {
    ERROR_CODES,
    EVENTS,
    NOTIFICATION_TYPES,
    ERROR_MESSAGES,
} from '../core/constants';
import { showNotification } from '../services/notification.service';
import { t } from 'i18next';

/**
 * Displays an application error notification by status code
 *
 * @param {Error} error
 */
export const handleError = (error) => {
    if (!error?.status) {
        throw error;
    }

    showNotification({
        text: t(ERROR_CODES[error?.status] || ERROR_CODES[500]),
        type: NOTIFICATION_TYPES.ERROR,
    });
};

/**
 * Displays an application error notification by error message,
 * If a matching translation key is found for the error, it will be displayed,
 * or the backend (english) error message if no key is found.
 * If the error matches with a regex pattern, it will also interpolate the corresponding captured values.
 * If there's no message, a generic error notification will be shown.
 *
 * @param {string} message - The error object
 * @param {Object|null} options - Translation interpolation and variables passing
 */
export const handleErrorByMessage = (message, options) => {
    let errorKey;
    let interpolationOptions;
    let regExpCaptured;

    if (message) {
        const { translationKey, interpolationVars = [] } =
            ERROR_MESSAGES.find((error) => {
                if (error.key instanceof RegExp) {
                    const [match, ...captured] = message.match(error.key) || [];

                    if (match) {
                        regExpCaptured = captured;
                    }

                    return Boolean(match);
                }

                return message.includes(error.key);
            }) || {};

        errorKey = translationKey || message;
        interpolationOptions = {
            ...interpolationVars.reduce(
                (acc, v, i) => ({
                    ...acc,
                    [v]: regExpCaptured[i],
                }),
                {}
            ),
            ...options,
        };
    }

    handleUserError(errorKey, interpolationOptions);
};

/**
 * Displays a success notification with a custom message,
 * or a generic success message if no key is provided.
 *
 * @param {string} key - The translation key to display in the notification
 * @param {string|null} options - For translations interpolation and passing of variables
 */
export const handleSuccess = (
    key = EVENTS.GENERIC.NOTIFICATION_SUCCESS,
    options = null
) => {
    const link = options?.link || null;

    showNotification({
        text: t(key, options),
        type: NOTIFICATION_TYPES.SUCCESS,
        link,
    });
};

/**
 * Displays an error notification with a custom message,
 * or a generic error message if no key is provided.
 *
 * @param {string} key - The translation key to display in the notification
 * @param {Object|null} options  - Translation interpolation and variables passing
 */
export const handleUserError = (
    key = 'core.generic.errorMessage',
    options = null
) => {
    showNotification({
        text: t(key, options),
        type: NOTIFICATION_TYPES.ERROR,
    });
};

/**
 * Handles the nested custom error messages included in some of our services,
 * @param {{message}} nestedError - The error object
 * @param {Object|null} options - Translation interpolation and variables passing
 */
export const handleNestedError = (nestedError, options) => {
    const { message } = nestedError || {};

    handleErrorByMessage(message, options);
};
