import dayjs from '../../services/wrappers/dayjs';

/**
 * Return the start of a month for the given date. The result will be in the local timezone.
 * @param {Date} date
 * @returns {Date}
 */
export default function startOfMonth(date) {
    return dayjs(date).startOf('month').toDate();
}
