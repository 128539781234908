import { reactive } from 'vue';

/**
 * @namespace
 * @property {reactive} modal The modal reactive object
 * @property {Promise} modal.component The modal's import promise.
 * @property {boolean} modal.isOpen Is the modal open or closed,
 * @property {object} modal.props The modal's props,
 * @property {function<any>} modal.eventHandler handler for modal events,
 */
export const modal = reactive({
    component: Promise,
    isOpen: false,
    props: {},
    eventHandler: null,
});

export const computedModal = () => ({
    ...modal,
});

/**
 *  Shows the upgrade modal
 *  @param {Object} modalProps The props object
 *  @param {string} modalProps.accountId account id
 *  @param {Object} modalProps.addOnData add on data
 *  @param {boolean} modalProps.canUpgrade is the user allowed to upgrade
 *  @param {string} modalProps.feature feature to upgrade
 *  @param {Object} modalProps.cannotUpgradeMessage Message object for when the user cannot update
 *  @param {string} modalProps.cannotUpgradeMessage.title Title of the message
 *  @param {string} modalProps.cannotUpgradeMessage.description Description of the message
 *  @param {function<any>} eventHandler  handler for modal internal events
 */
export const showSubscriptionUpgradeModal = (modalProps, eventHandler) => {
    Object.assign(modal, {
        component: import(
            '../../components/modal-async/modal-subscription-upgrade.vue'
        ),
        isOpen: true,
        eventHandler,
        props: {
            canUpgrade: false,
            feature: null,
            accountId: null,
            addOnData: null,
            cannotUpgradeMessage: {},
            ...modalProps,
        },
    });
};

export const closeModal = () => {
    modal.isOpen = false;
    handleModalEvent('modal-close');
};

export const handleModalEvent = (...args) => {
    typeof modal.eventHandler === 'function' && modal.eventHandler(...args);
};

export default {
    modal,
    computedModal,
    showSubscriptionUpgradeModal,
    handleModalEvent,
    closeModal,
};
