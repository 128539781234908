import formatInTimeZone from './format-in-time-zone';

/**
 * This function takes dates from the values of an object.
 * These can be Date instances in the system's local time or an ISO8601 string, and an IANA time zone name or offset string.
 * It then formats these dates in the target time zone regardless of the system's local time zone.
 * @param {Object<Date>} obj
 * @param {string} timeZone
 * @param {string} format
 * @returns {Object<string>}
 */
export default function formatValuesInTimeZone(obj, timeZone, format) {
    return Object.entries(obj).reduce(
        (accumulator, [key, date]) => ({
            ...accumulator,
            [key]: formatInTimeZone(date, timeZone, format),
        }),
        {}
    );
}
