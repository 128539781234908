import apiClient from '../../core/api/api-client.js';
import dayjs from '../wrappers/dayjs';
import { reactive } from 'vue';
import { state as authState } from '../auth.service.js';
import { SUBSCRIPTIONS } from '../../core/constants.js';
import {
    HEADER_X_FILENAME,
    downloadResponseAsFile,
} from '../file/file.service.js';

const subscriptionsBaseUrlVersion2 = '/api/subscriptions/v2';
const subscriptionsBaseUrlVersion3 = '/api/subscriptions/v3';

const headers = {
    'Content-Type': 'application/merge-patch+json',
};

/**
 * @typedef {Object} subscriptionState The state of the logged-in user account.
 * @property {'ASSET' | 'ACCOUNT'} subscriptionType The subscription type
 * @property {object} subscription in case of an account subscription, the subscription object.
 */
export const subscriptionState = reactive({
    subscription: null,
    gettingSubscriptionForLoggedInUser: null,
    subscriptionType: null,
    gettingSubscriptionTypeForLoggedInUser: null,
});

/**
 * Clear all subscription data
 */
export function clearSubscriptionState() {
    subscriptionState.subscription = null;
    subscriptionState.gettingSubscriptionForLoggedInUser = null;
    subscriptionState.subscriptionType = null;
    subscriptionState.gettingSubscriptionTypeForLoggedInUser = null;
}

export const getters = {
    get isAssetBasedSubscription() {
        return subscriptionState.subscriptionType === SUBSCRIPTIONS.TYPES.ASSET;
    },
    get isAccountBasedSubscription() {
        return (
            subscriptionState.subscriptionType === SUBSCRIPTIONS.TYPES.ACCOUNT
        );
    },
    get hasBusinessPortal() {
        if (!this.isAccountBasedSubscription) {
            return true;
        }

        const features =
            subscriptionState.subscription?.accountSubscription?.features;

        if (!features) {
            return false;
        }

        const bpFeature = features.templateFeatures.find(
            (e) => e.name === SUBSCRIPTIONS.FEATURES.BUSINESS_PORTAL
        );

        return bpFeature && bpFeature.enabled;
    },
};

/**
 * @param {string} accountId
 * @typedef {Object} subscription
 * @property {Object} accountSubscription
 *
 * @returns {Object} subscription
 */
export const getSubscription = (accountId) => {
    return apiClient.get(
        `${subscriptionsBaseUrlVersion2}/subscriptions/accounts/${accountId}`
    );
};

export const isResourceQuotaReached = async (accountId, resourceType) => {
    try {
        const response = await getResourceQuotas(accountId, resourceType);

        return response.limitReached;
    } catch {
        return false;
    }
};

export const getResourceQuotas = (accountId, resourceType) =>
    apiClient.get(
        `${subscriptionsBaseUrlVersion2}/subscriptions/resource-quotas`,
        {
            resourceType,
            accountId,
        }
    );

async function getSubscriptionForLoggedInUserWithErrorHandling() {
    try {
        subscriptionState.subscription = await getSubscription(
            authState.profile.accountId
        );
    } catch (e) {
        // SIR004 errors just mean that the account does not have an active or pending subscription
        if (e.nestedError?.code !== 'SIR004') {
            console.error(e);
        }
        subscriptionState.subscription = null;
    }

    return subscriptionState.subscription;
}

export async function getSubscriptionForLoggedInUser() {
    subscriptionState.gettingSubscriptionForLoggedInUser =
        getSubscriptionForLoggedInUserWithErrorHandling();

    return subscriptionState.gettingSubscriptionForLoggedInUser;
}

/**
 * Returns a promise of the logged in user's subscription. It defaults to a cached value, and only fetches if needed.
 * @returns {Promise<Object>}
 */
export function ensureSubscriptionForLoggedInUser() {
    if (
        subscriptionState.gettingSubscriptionForLoggedInUser instanceof Promise
    ) {
        return subscriptionState.gettingSubscriptionForLoggedInUser;
    }

    return getSubscriptionForLoggedInUser();
}

/**
 * Returns the type of the account.
 * - Legacy asset-based
 * - Account-based, from a subscription template
 * - Enterprise Plan, a.k.a. "Custom Offering"
 * @param {string} accountId
 * @returns {('ASSET'|'ACCOUNT'|'CUSTOM_OFFERING')}
 */
export async function getSubscriptionType(accountId) {
    return apiClient
        .get(
            `${subscriptionsBaseUrlVersion3}/subscription-types/accounts/${accountId}`
        )
        .then((response) => {
            const { type } = response;

            return type;
        });
}

export async function getSubscriptionTypeForLoggedInUser() {
    subscriptionState.gettingSubscriptionTypeForLoggedInUser =
        getSubscriptionType(authState.profile.accountId);

    subscriptionState.subscriptionType =
        await subscriptionState.gettingSubscriptionTypeForLoggedInUser;

    return subscriptionState.subscriptionType;
}

/**
 * Returns a promise of the logged in user's subscription type. It defaults to a cached value, and only fetches if needed.
 * @returns {Promise<Object>}
 */
export function ensureSubscriptionTypeForLoggedInUser() {
    if (
        subscriptionState.gettingSubscriptionTypeForLoggedInUser instanceof
        Promise
    ) {
        return subscriptionState.gettingSubscriptionTypeForLoggedInUser;
    }

    return getSubscriptionTypeForLoggedInUser();
}

export const getSubscriptionTemplates = (
    accountId = null,
    feature = null,
    params = {}
) => {
    const query = {};

    for (const [param, value] of Object.entries({
        accountId,
        feature,
        ...params,
    })) {
        if (value) {
            query[param] = value;
        }
    }

    return apiClient.get(`${subscriptionsBaseUrlVersion2}/templates`, query);
};

export const createSubscriptionFromTemplate = (payload) =>
    apiClient.post(
        `${subscriptionsBaseUrlVersion2}/subscription-templates`,
        payload
    );

export const activateSubscription = (payload) =>
    apiClient.post(
        `${subscriptionsBaseUrlVersion3}/account-subscriptions:activate`,
        payload
    );

export const updateAccountBasedSubscription = (subscriptionId, payload) =>
    apiClient.patch(
        `${subscriptionsBaseUrlVersion3}/account-subscriptions/${subscriptionId}`,
        payload,
        headers
    );

export const upgradeSubscription = async (payload) => {
    await apiClient.post(
        `${subscriptionsBaseUrlVersion2}/subscription-upgrade`,
        payload
    );

    return getSubscriptionForLoggedInUser();
};

export const getAssetSubscription = (assetId, resourceType) =>
    apiClient.get(`${subscriptionsBaseUrlVersion2}/subscription-features`, {
        assetId,
        resourceType,
    });

export const createSubscription = (payload) =>
    apiClient.post(`${subscriptionsBaseUrlVersion2}/subscriptions`, payload);

export const onboardAccountSubscription = (payload) =>
    apiClient.post(
        `${subscriptionsBaseUrlVersion3}/account-subscriptions:onboard`,
        payload
    );

/**
 * Filters disabled characteristics
 * @param {Array} characteristics
 * @returns {Array}
 */
export const filterCharacteristics = (characteristics) => {
    if (Array.isArray(characteristics) && characteristics.length > 0) {
        const enabledCharacteristics = [];

        characteristics.forEach((characteristic) => {
            if (characteristic.enabled) {
                const clonedCharacteristic = Object.assign({}, characteristic);

                if (Array.isArray(clonedCharacteristic.value)) {
                    clonedCharacteristic.value = filterCharacteristics(
                        clonedCharacteristic.value
                    );
                }

                enabledCharacteristics.push(clonedCharacteristic);
            }
        });

        return enabledCharacteristics;
    }

    return characteristics;
};

export const updateCardSubscription = (id, payload) =>
    apiClient.patch(
        `/api/billing/subscriptions/cards/${id}`,
        payload,
        {},
        headers
    );

export const updatePurchaseOrderNumber = (id, type, payload) =>
    apiClient.patch(
        `/api/billing/subscriptions/${type}/${id}/update`,
        payload,
        {},
        headers
    );

export const getStationSubscription = (id) =>
    apiClient
        .get(`/api/billing/subscriptions/stations/${id}`)
        .then(transformSubscription);

function transformSubscription(subscription) {
    const { renewalDate, scheduledUpdate } = subscription;

    if (renewalDate) {
        subscription.planEndDate = dayjs(renewalDate)
            .subtract(1, 'day')
            .format();
    }

    if (scheduledUpdate) {
        scheduledUpdate.date = new Date(scheduledUpdate.date);
    }

    return subscription;
}

export const updateSubscription = (subscriptionId, payload) =>
    apiClient.patch(
        `${subscriptionsBaseUrlVersion2}/subscriptions/${subscriptionId}`,
        payload,
        headers
    );

export const isCardPublicChargingAvailable = async (accountId) => {
    try {
        const subscription = await getSubscription(accountId);

        return subscription.accountSubscription.features.tokenPublicCharging
            .enabled;
    } catch (error) {
        console.error('isCardPublicChargingAvailable', error);

        return false;
    }
};

export const getSubscriptionAddOnData = (accountId, addOn) =>
    apiClient.get(`${subscriptionsBaseUrlVersion2}/subscription/add-on`, {
        accountId,
        addOn,
    });

export const getCardSubscription = (id) =>
    apiClient
        .get(`/api/billing/subscriptions/cards/${id}`)
        .then(transformSubscription);

export const activateSubscriptionAddOn = (payload) =>
    apiClient.post(
        `${subscriptionsBaseUrlVersion2}/subscription/add-on`,
        payload
    );

export const getMonthlyBillableAssetList = (accountId) =>
    apiClient.get(`${subscriptionsBaseUrlVersion2}/cost-calculations`, {
        accountId,
    });

export const downloadMonthlyBillableAsset = (reportId) =>
    apiClient
        .goFetch(
            `${subscriptionsBaseUrlVersion2}/cost-calculations/${reportId}/billable-assets`,
            {
                headers: {
                    'content-type': 'application/json',
                    accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                },
            }
        )
        .then(
            (response) =>
                new Response(response.body, {
                    headers: {
                        [HEADER_X_FILENAME]: 'Billable_Assets_Report.xlsx',
                    },
                })
        )
        .then(downloadResponseAsFile);
