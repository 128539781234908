import { shallowEqual } from '../../../utils/objects';

export default {
    /**
     * Checks if user can manually edit the location form.
     * @param {Object} state
     * @returns {boolean}
     */
    canEditAddressForm(state) {
        return (
            state.permissions?.canUpdateStation &&
            state.knowsAddress &&
            !state.belongsToSite
        );
    },

    /**
     * Checks if user can select a new site.
     * @param {Object} state
     * @returns {boolean}
     */
    canSelectSite(state) {
        return (
            state.belongsToSite &&
            Boolean(state.availableSites.length) &&
            !state.site
        );
    },

    /**
     * Filters available sites by search query.
     * @param {Object} state
     * @returns {Array}
     */
    queriedSites(state) {
        return state.availableSites.filter((site) => {
            return site.name
                .toLowerCase()
                .includes(state.siteSearchQuery.toLowerCase());
        });
    },

    /**
     * Returns `true` if both belongs to site and known address toggles are off, `false` in any other case.
     * This means that the station's location is going to be obtained by specifying coords in the coords form or map.
     * @param {Object} state
     * @returns {boolean}
     */
    locationFromCoords(state) {
        return !state.belongsToSite && !state.knowsAddress;
    },

    /**
     * Returns `true` if belongs to site is checked but there is not assigned site yet.
     * @param {Object} state
     * @returns {boolean}
     */
    waitingForSiteAssignment(state) {
        return Boolean(state.belongsToSite && !state.site);
    },

    /**
     * Checks whether the address read-only form should show an address or a placeholder when waiting for a site to be related to the station.
     * If `waitingForSiteAssignment` is `true` returns the selected site object; if not returns `false`.
     * @param {Object} state
     * @param {Object} getters
     * @returns {Object/boolean/undefined}
     */
    siteLocationPreviewVisible(
        { availableSites, selectedSiteId },
        { waitingForSiteAssignment }
    ) {
        return (
            waitingForSiteAssignment &&
            availableSites.find((site) => site.id === selectedSiteId)
        );
    },

    /**
     * Decides if the `belongsToSite` toggle should be rendered or not.
     * @param {Object} state
     * @returns {boolean}
     */
    siteToggleVisible({
        permissions: { canUpdateStation, canCreateSite },
        availableSites,
        sameAccount,
    }) {
        const sitesAvailable = Boolean(availableSites.length);

        return (
            canUpdateStation &&
            (sitesAvailable ||
                (!sitesAvailable && sameAccount && canCreateSite))
        );
    },

    /**
     * Decides if the save button is active when the station belongs to a site.
     * @param {Object} state
     * @param {Object} state.site
     * @param {Object} getters
     * @param {Object} getters.hasGeoCoordsOrAdvenirModelChanged
     * @returns {boolean}
     */
    saveEnabledWhenBelongsToSite(
        { site },
        { hasGeoCoordsOrAdvenirModelChanged }
    ) {
        return Boolean(site && hasGeoCoordsOrAdvenirModelChanged);
    },

    /**
     * Checks if any model has been updated.
     * @param {Object} state
     * @param {Object} state.addressModel
     * @param {Object} state.address
     * @param {Object} getters
     * @param {Object} getters.hasGeoCoordsOrAdvenirModelChanged
     * @returns {boolean}
     */
    hasAnyModelChanged(
        { addressModel, address, direction, directionModel },
        { hasGeoCoordsOrAdvenirModelChanged }
    ) {
        return (
            !shallowEqual(addressModel, address) ||
            direction !== directionModel ||
            hasGeoCoordsOrAdvenirModelChanged
        );
    },

    /**
     * Checks if any of the geocoordinates or Advenir model has been updated.
     * @param {Object} state
     * @param {Object} state.geocoordinatesModel
     * @param {Object} state.geocoordinates
     * @param {Object} state.isAdvenirEnabledOnStation
     * @param {boolean} state.isAdvenirEnabledOnStationModel
     * @returns {boolean}
     */
    hasGeoCoordsOrAdvenirModelChanged({
        geocoordinates,
        geocoordinatesModel,
        isAdvenirEnabledOnStation,
        isAdvenirEnabledOnStationModel,
    }) {
        return (
            !shallowEqual(geocoordinatesModel, geocoordinates) ||
            isAdvenirEnabledOnStation !== isAdvenirEnabledOnStationModel
        );
    },

    /**
     * Checks if Advenir feature is available.
     * @param {Object} state
     * @param {Object} state.isAdvenirEnabledOnAccount
     * @param {Object} state.addressModel
     * @returns {boolean}
     */
    isAdvenirAvailable({ isAdvenirEnabledOnAccount, addressModel }) {
        return addressModel.country === 'FR' && isAdvenirEnabledOnAccount;
    },
};
