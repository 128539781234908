import { poll, TimeoutError } from '../../../../utils/async';
import { handleUserError } from '../../../../utils/handle-generic-notifications';
import { getStation } from '../../../../services/api/stations/v1.api';

const pollStationInterval = 2_000;
const pollStationDuration = 20_000;

export default function getReadStationActions() {
    return {
        async getStation({ state, commit }) {
            try {
                const station = await getStation(state.stationId);

                commit('updateStationData', { station });

                return station;
            } catch (error) {
                if (!error.status) {
                    throw error;
                }

                handleUserError(
                    'stations.components.connectorsControl.fetchConnectorStatusFailed',
                    {
                        delay: 5_000,
                    }
                );
            }
        },

        async pollStation({ dispatch }, { duration, interval, timeout } = {}) {
            try {
                await poll(() => dispatch('getStation'), {
                    until: duration ?? pollStationDuration,
                    interval: interval ?? pollStationInterval,
                    timeout,
                });
            } catch (error) {
                if (timeout && error instanceof TimeoutError) {
                    handleUserError('generic.error.longerThanUsualRefresh', {
                        delay: 5_000,
                    });
                } else {
                    throw error;
                }
            }
        },
    };
}
