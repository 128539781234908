/**
 * Transform a single connector spec object from the format returned by the private station endpoint to the format
 * returned by the connector spec endpoint. This method does not modify the object passed as its argument.
 * @param {Object} spec
 * @param {number} index The index at which the spec appears in the array
 * @returns {Object}
 */
export function privateStationEndpointFormatToConnectorSpecEndpointFormat(
    spec,
    index
) {
    const { standard, ...rest } = spec;

    return { index: index + 1, type: standard, ...rest };
}

/**
 * Transform a single connector spec object from the format returned by the connector spec endpoint to the format
 * returned by the private station endpoint. This method does not modify the object passed as its argument.
 * @param {Object} spec
 * @returns {Object}
 */
export function connectorSpecEndpointFormatToPrivateStationEndpointFormat(
    spec
) {
    // eslint-disable-next-line no-unused-vars
    const { index: _, type, ...rest } = spec;

    return { standard: type, ...rest };
}

/**
 * Transforms an address from the format used by the station management endpoints to the format used by other CME endpoints.
 * @param {Object} address
 * @returns {Object}
 */
export function stationManagementAddressToCmeAddress(address) {
    const { street, ...addressRest } = address;

    return { ...addressRest, streetName: street };
}

/**
 * Transforms an address from the format used by other CME endpoints to the format used by the station management endpoints.
 * @param {Object} address
 * @returns {Object}
 */
export function cmeAddressToStationManagementAddress(address) {
    const { streetName, ...addressRest } = address;

    return { ...addressRest, street: streetName };
}

/**
 * An object holding connector information regarding its details, settings, and the identifying data from the EVSE it belongs to
 * @typedef {Object} EnrichedConnector
 * @property {string} id The connector's UID in the Everon backend
 * @property {string} code The identifier that the station uses over OCPP to identify this connector (per EVSE)
 * @property {string} evseId The evse's UID in the Everon backend
 * @property {string} evseCode The identifier that the station uses over OCPP to identify this EVSE
 * @property {string} availabilityStatus The connector's status upper-cased (i.e. "AVAILABLE", "CHARGING", etc.)
 * @property {string} statusUpdatedAt ISO dateString denoting when the status was last updated
 * @property {string} type Connector type (i.e. "IEC_62196_T2", "CHADEMO", etc.)
 * @property {string} format "SOCKET" or "CABLE"
 * @property {string} powerType "AC_1_PHASE", "AC_2_PHASE", "AC_3_PHASE" or "DC"
 * @property {number} voltage Connector voltage
 * @property {number} amperage Connector amperage
 */

/**
 * Returns an array of all connectors in EVSEs
 * @param {Array} evses
 * @returns {Array<EnrichedConnector>}
 */
export function enrichedConnectorsFromEvses(evses = []) {
    return evses.flatMap((evse) => {
        const connectors = Array.isArray(evse.connectors)
            ? evse.connectors
            : [];

        return connectors.map(
            ({
                id,
                code,
                specification,
                availabilityStatus,
                statusUpdatedAt,
            }) => ({
                id,
                code,
                evseId: evse.id,
                evseCode: evse.code,
                availabilityStatus,
                statusUpdatedAt,
                format: specification.format,
                powerType: specification.powerType,
                type: specification.type,
                amperage: specification.amperage,
                voltage: specification.voltage,
                specification,
            })
        );
    });
}

/**
 * Get electrical power for a connector rounded to 1 decimal place in case it is a decimal number.
 * @param {number} voltage Potential in Volt
 * @param {number} amperage Current in Ampere
 * @param {string} powerType 'AC_1_PHASE', 'AC_2_PHASE', 'AC_3_PHASE' or 'DC'
 * @returns {number} Power in kW
 */
export function calculateElectricalPower(voltage, amperage, powerType) {
    const powerTypeMultiplier = powerType === 'AC_3_PHASE' ? 3 : 1;
    const electricalPower = (voltage * amperage * powerTypeMultiplier) / 1000;

    return Math.round(electricalPower * 10) / 10;
}
