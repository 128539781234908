/**
 * low level, fetch based api client
 * makes network requests to the server
 * reads tenant configuration from the configuration object
 * reads access token from the session storage
 * exposes REST methods for the application services
 * exposes a generic `goFetch` method to return a raw Response
 * exposes a generic `goFetchJOSN` method to return a JavaScript object
 */

import { getters } from '../../services/config.service';
import OktaService, { handleOktaError } from '../../services/okta.service';
import { parseJSON } from './json-parser';
const apiUrl = `https://${EVERON_API_URL}`;

/**
 * Resolves the api url using the EVERON.apiUrl configuration
 *
 * @param {string} url - The pathname or a static resource url
 * @returns {string} url - The absolute URI
 */
export function resolveApiUrl(url) {
    if (isStaticResource(url)) {
        return url;
    }

    return url.startsWith('/api') ? `${apiUrl}${url}` : url;
}

/**
 * Checks whether a given url is a static resource. Determined by a dot before file extension
 *
 * @param {string} url
 * @returns {boolean} isStaticResource
 */
function isStaticResource(url) {
    return url.includes('.');
}

const apiClientInstance = {
    /**
     * @typedef {Object} FetchOptions
     * @property {'CONNECT'|'DELETE'|'GET'|'HEAD'|'OPTIONS'|'PATCH'|'POST'|'PUT'|'TRACE'} method - The request method
     * @property {Object} params - Query params
     * @property {Object} headers - Request headers
     * @property {Object} body - Request body
     */

    /**
     * Uses the fetch JavaScript API defined by the fetch standard
     * {@link https://fetch.spec.whatwg.org/ fetch} to perform networking functionality
     * Adds authorization and tenantId headers.
     * Returns a Promise that resolves to the JavaScript object
     * in case of an empty response body it returns a Response object instead
     * @param {string|Request|Object} resource - URI pathname
     * @param {FetchOptions} [options] - fetch options
     * @returns {Promise<Response>} Response - HTTP Response object
     */
    async goFetch(resource, options = {}) {
        if (!resource) {
            throw new Error('resource path is mandatory');
        }

        let accessToken;

        try {
            accessToken = await OktaService.getOktaAccessToken();
        } catch (error) {
            handleOktaError(error);
        }

        const tenantId = getters?.tenantId;

        const defaultRequestOptions = {
            headers: {
                ...(accessToken && { authorization: `Bearer ${accessToken}` }),
                ...(tenantId && { tenantid: tenantId }),
            },
            mode: 'cors',
            credentials: 'include',
        };

        const url = new URL(resolveApiUrl(resource));

        const { params, headers } = options;

        if (params) {
            // remove undefined params
            Object.keys(params).forEach(
                (key) => params[key] === undefined && delete params[key]
            );

            url.search = new URLSearchParams(params).toString();
            delete options.params;
        }

        if (headers) {
            defaultRequestOptions.headers = {
                ...defaultRequestOptions.headers,
                ...headers,
            };
            delete options.headers;
        }

        return fetch(url, { ...defaultRequestOptions, ...options });
    },

    /**
     * Uses the fetch JavaScript API defined by the fetch standard
     * {@link https://fetch.spec.whatwg.org/ fetch} to perform networking functionality
     * Adds authorization and tenantId headers.
     * Returns a Promise that resolves to the JavaScript object
     * in case of an empty response body it returns a Response object instead
     * @param {string} resource - URI pathname
     * @param {FetchOptions} [options] - fetch options
     * @returns {Promise<JSON>} Response - Resolves to JavaScript object
     */
    async goFetchJSON(resource, options) {
        const response = await this.goFetch(resource, options);

        return parseJSON(response);
    },

    /**
     * Uses the fetch JavaScript API defined by the fetch standard
     * {@link https://fetch.spec.whatwg.org/ fetch} to perform a GET request
     * Adds authorization and tenantId headers.
     * Returns a Promise that resolves to the JavaScript object
     * @param {string} path - URI pathname
     * @param {Object} [params] - URI query params object
     * @param {Object} [headers] - URI Request headers
     * @returns {Promise<JSON>} Response - Resolves to JavaScript object
     */
    get(path, params = {}, headers = {}) {
        return this.goFetchJSON(path, {
            params,
            headers,
            method: 'GET',
        });
    },

    /**
     * Uses the fetch JavaScript API defined by the fetch standard
     * {@link https://fetch.spec.whatwg.org/ fetch} to perform a GET request
     * Adds authorization and tenantId headers.
     * Returns a Promise that resolves to the Response object
     * @param {string} path - URI pathname
     * @param {Object} [params] - URI query params object
     * @param {Object} [headers] - URI Request headers
     * @returns {Promise<Response>} Response - HTTP Response object
     */
    head(path, params = {}, headers = {}) {
        return this.goFetch(path, {
            params,
            headers,
            method: 'HEAD',
        });
    },

    /**
     * Uses the fetch JavaScript API defined by the fetch standard
     * {@link https://fetch.spec.whatwg.org/ fetch} to perform a POST request
     * Adds authorization and tenantId headers.
     * Stringifies request body object.
     * Returns a Promise that resolves to the JavaScript object
     * @param {string} path - URI pathname
     * @param {Object} [body] - URI Request body
     * @param {Object} [params] - URI query params object
     * @param {Object} [headers] - URI Request headers
     * @returns {Promise<JSON>} Response - JavaScript object
     */
    post(path, body, params = {}, headers = {}) {
        return this.goFetchJSON(path, {
            params,
            headers: {
                'Content-Type': 'application/json;charset=UTF-8',
                ...headers,
            },
            body: body && JSON.stringify(body),
            method: 'POST',
        });
    },

    /**
     * Uses the fetch JavaScript API defined by the fetch standard
     * {@link https://fetch.spec.whatwg.org/ fetch} to perform a PUT request
     * Adds authorization and tenantId headers.
     * Stringifies request body object.
     * Returns a Promise that resolves to the JavaScript object
     * @param {string} path - URI pathname
     * @param {Object} [body] - URI Request body
     * @param {Object} [params] - URI query params object
     * @param {Object} [headers] - URI Request headers
     * @returns {Promise<JSON>} Response - JavaScript object
     */
    put(path, body, params = {}, headers = {}) {
        return this.goFetchJSON(path, {
            params,
            headers: {
                'Content-Type': 'application/json;charset=UTF-8',
                ...headers,
            },
            body: body && JSON.stringify(body),
            method: 'PUT',
        });
    },

    /**
     * Uses the fetch JavaScript API defined by the fetch standard
     * {@link https://fetch.spec.whatwg.org/ fetch} to perform a PATCH request
     * Adds authorization and tenantId headers.
     * Stringifies request body object.
     * Returns a Promise that resolves to the JavaScript object
     * @param {string} path - URI pathname
     * @param {Object} [body] - URI Request body
     * @param {Object} [params] - URI query params object
     * @param {Object} [headers] - URI Request headers
     * @returns {Promise<JSON>} Response - JavaScript object
     */
    patch(path, body, params = {}, headers = {}) {
        return this.goFetchJSON(path, {
            params,
            headers: {
                'Content-Type': 'application/json;charset=UTF-8',
                ...headers,
            },
            body: body && JSON.stringify(body),
            method: 'PATCH',
        });
    },

    /**
     * Uses the fetch JavaScript API defined by the fetch standard
     * {@link https://fetch.spec.whatwg.org/ fetch} to perform a DELETE request
     * Adds authorization and tenantId headers.
     * Returns a Promise that resolves to the JavaScript object
     * @param {string} path - URI pathname
     * @param {Object} [body] - URI Request body
     * @param {Object} [params] - URI query params object
     * @param {Object} [headers] - URI Request headers
     * @returns {Promise<JSON>} Response - HTTP Response object
     */
    delete(path, body, params = {}, headers = {}) {
        return this.goFetchJSON(path, {
            params,
            headers,
            body: body && JSON.stringify(body),
            method: 'DELETE',
        });
    },
};

const apiClient = Object.freeze(apiClientInstance);

export default apiClient;
