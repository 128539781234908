<template>
    <span
        class="evo-fixed evo-top-0 evo-left-0 evo-z-10 evo-p-xs language-selector"
        :class="{ 'language-selector--right': props.isPositionRight }"
    >
        <jolt-dropdown-button
            ref="dropdownRef"
            :button-config="buttonConfig"
            :actions="languagesList"
            :open-panel-above="openPanelAbove"
            :align-panel-left="!props.isPositionRight"
            :class="{ 'evbox-language-selector': isEvboxTenantBranding }"
        >
            {{ activeLanguageLabel }}
        </jolt-dropdown-button>
    </span>
</template>

<script setup>
import { ref, onMounted, watch, computed } from 'vue';
import {
    getTenantLanguages,
    getLanguageFromLocalStorage,
} from '../../services/language/language.service.js';
import { evboxTenantBranding } from '../../services/config.service.js';
import { useTranslation } from 'i18next-vue';

const { i18next } = useTranslation();

const props = defineProps({
    isPositionRight: {
        default: false,
    },
    openPanelAbove: {
        default: false,
    },
});

const isEvboxTenantBranding = ref(false);

const languages = ref([]);
const activeLanguage = ref(null);
const activeLanguageLabel = ref(null);

watch(activeLanguage, async (value) => {
    await i18next.changeLanguage(value);
    localStorage.setItem('NG_TRANSLATE_LANG_KEY', value);
});

const buttonConfig = {
    contentType: 'icon-right',
    iconId: 'icon-arrow',
    iconClasses: 'evo-transform evo-rotate-180',
    variant: 'tertiary',
};

const dropdownRef = ref(null);

function updateLanguage(code, label) {
    activeLanguage.value = code;
    activeLanguageLabel.value = label;
}

const languagesList = computed(() => {
    return languages.value.map((e) => ({
        label: e.name,
        onClick() {
            updateLanguage(e.tag, e.shortCode);
            dropdownRef.value.togglePanelState(false);
        },
    }));
});

onMounted(async () => {
    languages.value = await getTenantLanguages();
    activeLanguage.value = getLanguageFromLocalStorage() || languages.value[0];
    activeLanguageLabel.value = languages.value.find(
        (e) => e.tag === activeLanguage.value
    )?.shortCode;

    isEvboxTenantBranding.value = evboxTenantBranding();
});
</script>

<style scoped>
.language-selector {
    background-color: transparent;
    border: none;
    font-size: 12px;
}

.language-selector :deep(.j-select-input) {
    max-width: 90px;
}

.language-selector :deep(.j-option-panel) {
    min-width: 130px;
}

.language-selector.language-selector--right :deep(.j-option-panel) {
    transform: translate(-40px, var(--j-spacing-xs));
}

.evbox-language-selector > :deep(button span) {
    color: white;
}

.evbox-language-selector > :deep(button:hover) {
    background-color: var(--j-color-neutral-6);
}
</style>
