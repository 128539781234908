<template>
    <div
        data-testid="spinner"
        class="evo-flex evo-items-center evo-text-brand evo-opacity-50"
        :class="[
            {
                'evo-justify-start': justify === 'start',
                'evo-justify-center': justify === 'center',
                'evo-justify-end': justify === 'end',
            },
        ]"
    >
        <svg
            xmlns="http://www.w3.org/2000/svg"
            class="evo-w-12 evo-h-12 stroke-current spin"
            :class="[
                {
                    'evo-w-6 evo-h-6': size === 's',
                    'evo-w-8 evo-h-8': size === 'm',
                    'evo-w-12 evo-h-12': size === 'l',
                },
            ]"
            viewBox="0 0 24 24"
            fill="none"
        >
            <circle
                cx="12"
                cy="12"
                r="11"
                stroke-width="2"
                stroke-opacity="0.5"
            />
            <circle
                cx="12"
                cy="12"
                r="11"
                stroke-width="2"
                stroke-dasharray="50"
                stroke-dashoffset="25"
            />
        </svg>
    </div>
</template>

<script>
export default {
    name: 'evo-spinner',
    props: {
        size: {
            type: String,
            default: 'l',
        },
        justify: {
            type: String,
            default: 'center',
        },
    },
};
</script>
<style scoped>
.stroke-current {
    stroke: currentColor;
}

.spin {
    animation: spin 0.8s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
</style>
