const storage = window.localStorage;
const namespace = 'EV_BOX_NEXT.';

export const set = (key, value) => {
    storage.setItem(namespace + key, JSON.stringify(value));
};

export const get = (key, requireParse = true) => {
    const value = storage.getItem(namespace + key);

    if (requireParse) {
        return JSON.parse(value) || null;
    }

    return value;
};

export const remove = (key) => {
    storage.removeItem(namespace + key);
};

export const clear = () => {
    storage.clear();
};
