export default {
    statusFilterChanged(state, statusFilter) {
        state.selectedStationsStatusFilter = statusFilter;
    },

    connectorsStatusesFilterChanged(state, statusesFilter) {
        state.selectedConnectorsStatusesFilter = statusesFilter;
    },

    stationsLoadErrorReceived(state, error) {
        state.stationsLoadError = error;
    },

    fetchingStations(state, inProgress) {
        state.fetchingStations = inProgress;
    },
};
