export default {
    loadingStationsStarted({ commit }, { stationStatus, connectorStatuses }) {
        commit('fetchingStations', true);
        commit('update', ['stations', []]);

        commit('statusFilterChanged', stationStatus);
        commit('connectorsStatusesFilterChanged', connectorStatuses);
    },

    loadingStationsSucceeded({ commit, state }, { stations, pagination }) {
        commit('stationsLoadErrorReceived', null);
        commit('update', ['stations', stations]);
        commit('update', [
            'pagination',
            { ...state.pagination, ...pagination },
        ]);
        commit('fetchingStations', false);
    },

    loadingStationsFailed({ commit }, error) {
        commit('stationsLoadErrorReceived', error);
        commit('fetchingStations', false);
    },
};
