/**
 * Checks if a station is considered a hub
 * @param {Object} station
 * @returns {boolean}
 */
export function isHub(station) {
    return Array.isArray(station.evses) && station.evses.length > 1;
}

/**
 * Get pagination props object from stations list endpoint response
 * @param {Object} stationsListResponse
 * @returns {Object}
 */
export function getStationsPagination({
    page,
    size,
    totalElements,
    totalPages,
}) {
    const first = page === 0;
    const last = page === totalPages - 1;

    return { totalElements, totalPages, first, last, number: page, size };
}

/**
 * Checks if a station is considered as one within site
 * @param {Object} station
 * @param {Object} station.site
 * @param {string} station.status
 * @returns {boolean}
 */
function assignedToSite({ site, status }) {
    return Boolean(site) && status !== 'inactive';
}

/**
 * Creates a site object
 * @param {Object} station
 * @returns {Object}
 */
function transformSiteData(station) {
    const { site, location, account } = station;

    const address = location?.address ?? '';
    const accountName = account?.name ?? '';
    const accountId = account?.id ?? '';

    return {
        site: {
            ...site,
            location: address,
            accountName,
            accountId,
        },
    };
}

/**
 * Groups stations by site. Stations without site go as standalone
 * @param {Array} stations - the original list of stations returned by BE
 * @returns {Object}
 */
function groupBySite(stations) {
    const sites = new Map();
    const standalone = [];

    return stations.reduce(
        (acc, station) => {
            const wrapper = {
                station,
            };

            if (assignedToSite(station)) {
                const siteName = station.site.name;

                if (!acc.sites.has(siteName)) {
                    acc.sites.set(siteName, [transformSiteData(station)]);
                }

                acc.sites.get(siteName).push(wrapper);
            } else {
                acc.standalone.push(wrapper);
            }

            return acc;
        },
        {
            sites,
            standalone,
        }
    );
}

/**
 * Structures the content coming from server into ordered [sites|stations] array
 * @param {Array} content
 * @returns {Array}
 */
export function splitSitesAndStandalone(content) {
    const { sites = new Map(), standalone = [] } = groupBySite(content);
    const sitesContent = [...sites.values()].map((site, index, collection) => {
        if (index < collection.length - 1) {
            site[site.length - 1].isLastInSite = true;
        }

        return site;
    });

    return [].concat(
        sitesContent.length
            ? [{ header: ['generic.entity.sites', 'Sites'] }]
            : [],
        ...sitesContent,
        sitesContent.length && standalone.length
            ? [
                  {
                      header: [
                          'stations.list.standaloneStations',
                          'Standalone stations',
                      ],
                      standalone: true,
                  },
              ]
            : [],
        standalone
    );
}

/**
 * Convert to camel case a given string, used mainly for station connector types format coming from backend
 * @param {string} text
 * @returns {string}
 */
export function toCamelCase(text) {
    return text
        .toLowerCase()
        .replace(/([-_][a-z0-9])/gi, ($1) =>
            $1.toUpperCase().replace(/^[-_]/g, '')
        );
}

/**
 * Formats number passed as argument to locale by adding decimal and thousand separators.
 * @param {number} number
 * @returns {string}
 */
export function formatNumberForLocale(number) {
    return new Intl.NumberFormat(getLocale()).format(number);
}

/**
 * Transforms a string array into an object with the values as keys and true as value.
 * @param {Array}arr
 * @returns {*}
 */
export function strArrayToMap(arr = []) {
    return arr.reduce((acc, value) => {
        if (typeof value === 'string') {
            acc[value] = true;
        }

        return acc;
    }, Object.create(null));
}

/**
 * Returns locale which is stored in localStorage.NG_TRANSLATE_LANG_KEY, with 'en-GB' as fallback
 * @returns {string}
 */
function getLocale() {
    return localStorage.getItem('NG_TRANSLATE_LANG_KEY') || 'en-GB';
}
