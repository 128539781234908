import {
    ensureSubscriptionForLoggedInUser,
    ensureSubscriptionTypeForLoggedInUser,
} from '../services/subscription/subscription.service.js';
import {
    state as authState,
    getters as authGetters,
} from '../services/auth.service.js';
import { getBillingInfo } from '../services/account/account.service.js';
import { SUBSCRIPTIONS } from '../core/constants.js';
import { hasPermission } from '../utils/permissions.js';

const { TYPES, STATUS } = SUBSCRIPTIONS;

/**
 * Checks if the user is a new self signed up user
 * @returns {boolean}
 */
export async function isSelfSignupOnboarding() {
    const [subscription, subscriptionType] = await Promise.all([
        ensureSubscriptionForLoggedInUser(),
        ensureSubscriptionTypeForLoggedInUser(),
    ]);

    if (!hasPermission('ACCOUNT_SUBSCRIPTION:UPGRADE')) {
        return false;
    }

    if (subscription) {
        return false;
    }

    return subscriptionType === TYPES.ACCOUNT;
}

/**
 * Checks if the user is a new employee
 * @returns {boolean}
 */
export async function isEmployeeOnboarding() {
    const { accountId } = authState.profile;
    const [billing, subscriptionType] = await Promise.all([
        getBillingInfo(accountId),
        ensureSubscriptionTypeForLoggedInUser(),
    ]);

    const isAccountAccount = subscriptionType === TYPES.ACCOUNT;
    const isBillingIncomplete = Boolean(billing?.billingAddress === null);

    return authGetters.isDriver && isBillingIncomplete && isAccountAccount;
}

/**
 * Checks if the user has an existing inactive subscription
 * @returns {boolean}
 */
export async function isOnboardingOnBehalf() {
    const subscription = await ensureSubscriptionForLoggedInUser();

    if (!subscription) {
        return false;
    }

    const { accountSubscription: { status = null } = {} } = subscription;

    return status !== STATUS.ACTIVATED;
}

export default {
    isEmployeeOnboarding,
    isSelfSignupOnboarding,
    isOnboardingOnBehalf,
};
