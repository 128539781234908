import { findTransactionForEvse } from '../utils';
import { sleep } from '../../../../utils/async';
import { executeStationCapability } from '../../../../services/api/stations/v1/capabilities/capabilities.api';
import {
    handleSuccess,
    handleUserError,
} from '../../../../utils/handle-generic-notifications';

const resetCapabilityName = 'EVSE_RESET';
const CONNECTOR_STATUS__FETCH_DELAY = 2 * 1000;
const NOTIFICATION_DELAY = 5_000;

export default function getResetActions() {
    return {
        confirmResetEvse({ commit }, { connector }) {
            commit('confirmOperation', { action: 'reset', connector });
        },

        async resetEvse({ state, commit, dispatch }) {
            commit('startCurrentOperation');

            const transaction = findTransactionForEvse(
                state.openTransactions,
                state.currentConnector
            );

            try {
                await executeStationCapability(
                    state.stationId,
                    resetCapabilityName,
                    {
                        evseCode: state.currentConnector.evseCode,
                        hardReset: true,
                    }
                );
            } catch (error) {
                if (!error.status) {
                    throw error;
                }

                handleUserError(
                    'stations.components.connectorsControl.resetConnectorFailed',
                    {
                        connectorId: state.currentConnector.code,
                        delay: NOTIFICATION_DELAY,
                    }
                );

                commit('finishCurrentOperation');
                await dispatch('getStation');

                return;
            }

            handleSuccess(
                'stations.components.connectorsControl.resetConnectorAccepted',
                {
                    connectorId: state.currentConnector.code,
                    delay: NOTIFICATION_DELAY,
                }
            );

            if (transaction != null) {
                await dispatch(
                    'waitUntilTransactionDisappears',
                    transaction.id
                );
            }

            commit('finishCurrentOperation');

            await sleep(CONNECTOR_STATUS__FETCH_DELAY);
            await dispatch('pollStation');
        },
    };
}
