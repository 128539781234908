import apiClient from '../../../../../core/api/api-client';
import { StationAutostartDataSource } from '../../../../data-sources';

const stationsCapabilitiesCache = new Map();

/**
 * Transforms the capabilities array into an object that allows you to query capabilities in several ways:
 * - With the names of the capabilities as keys: eg. `capabilities['CONNECTOR_UNLOCK']`
 * - With the type of the capabilities as keys, as an array: eg. `capabilities.byType['EXECUTABLE'][0]`
 * - With an iterable array: eg. `capabilities.iterable[0]`
 * @param {Array} capabilities
 * @returns {Object}
 */
function transformStationsCapabilities(capabilities) {
    return capabilities.reduce(
        (acc, capability) => {
            const { type, name } = capability;

            acc[name] = capability;
            acc.byType[type] = acc.byType[type] || [];
            acc.byType[type].push(capability.name);
            acc.iterable.push(capability.name);

            return acc;
        },
        { byType: {}, iterable: [] }
    );
}

/**
 * Executes an executable station's capability.
 *
 * The executable station capability names and needed data are:
 *  - Unlock connector capability
 *      - Name: CONNECTOR_UNLOCK
 *      - Data:  An object with properties:
 *          - `evseCode`: sting containing the identity code of the EVSE the connector belongs to
 *          - `connectorCode`: sting containing the identity code of the connector
 *
 * - reset EVSE capability
 *      - Name: EVSE_RESET
 *      - Data:  An object with properties:
 *          - `evseCode`: sting containing the identity code of the EVSE
 *          - `hardReset`: boolean specifying the reset type, use `true` for hard, `false` for soft
 *
 * @param {string} stationId
 * @param {string} capability
 * @param {*} data
 * @returns {Promise}
 */
export function executeStationCapability(stationId, capability, data) {
    return apiClient.post(
        `/api/stations/v1/${stationId}/capabilities/${capability}:execute`,
        data
    );
}

/**
 * Loads the station capabilities based on the id received.
 *
 * @param {string} stationId
 * @returns {Promise} It returns a promise which is resolved to an object with the names of the capabilities as keys
 * and the capability object as the value, it also has a field named byType where the capabilities are grouped by their type
 * and another field named iterable which is an array containing all the capabilities.
 * This arrangement makes easier to test for the presence of a capability and also to iterate through the whole collection
 * or only those with some specific type.
 *
 * Ex: For the following response
 * [
 *    {"type": "EXECUTABLE", "name": "STATION_RESET"},
 *    {"type": "SIMPLE", "name": "REMOTE_START_STOP"},
 *    {"type": "SIMPLE","name": "RFID_READER"}
 * ]
 *
 * the result will be
 * {
 *   STATION_RESET: {type: "EXECUTABLE", name: "STATION_RESET"},
 *   REMOTE_START_STOP: {type: "SIMPLE", name: "REMOTE_START_STOP"},
 *   RFID_READER: {type: "SIMPLE", name: "RFID_READER"},
 *   byType: {
 *     EXECUTABLE: [ "STATION_RESET" ],
 *     SIMPLE: [ "REMOTE_START_STOP", "RFID_READER" ]
 *   },
 *   iterable: [ "STATION_RESET", "RFID_READER", "REMOTE_START_STOP" ]
 * }
 */
export function getStationCapabilities(stationId) {
    const silencedStatuses = [500, 502, 504];

    if (stationsCapabilitiesCache.has(stationId)) {
        return new Promise((resolve) =>
            setTimeout(
                resolve,
                0,
                transformStationsCapabilities(
                    stationsCapabilitiesCache.get(stationId)
                )
            )
        );
    }

    return (
        apiClient
            .get(`/api/stations/v1/${stationId}/capabilities`)
            .then((data = []) => {
                stationsCapabilitiesCache.set(stationId, data);

                return data;
            })
            .then(transformStationsCapabilities)
            // The catch block silence rejected requests with error status `500`, `502` and `504` and returns empty object.
            // Other error statuses are handled as usual.
            // More info here:
            //  - https://myevbox.atlassian.net/browse/EBH-1209
            //  - https://myevbox.atlassian.net/browse/EBH-1336
            // This should be safe to revert after: https://myevbox.atlassian.net/browse/EBH-1291
            .catch((error) =>
                silencedStatuses.includes(error.status)
                    ? {}
                    : Promise.reject(error)
            )
    );
}

/**
 * Read a station's capability
 * @param {string} stationId
 * @param {string} capability
 * @returns {Promise}
 */
function readStationCapability(stationId, capability) {
    return apiClient.post(
        `/api/stations/v1/${stationId}/capabilities/${capability}:read`
    );
}

/**
 * Write a station's capability
 * @param {string} stationId
 * @param {string} capability
 * @param {*} data
 * @returns {Promise}
 */
function writeStationCapability(stationId, capability, data) {
    return apiClient.post(
        `/api/stations/v1/${stationId}/capabilities/${capability}:write`,
        data
    );
}

/**
 * Create a data source for a station's autostart capability
 * @param {string} stationId
 * @returns {Object}
 */
export function createAutostartDataSource(stationId) {
    return new StationAutostartDataSource(stationId, {
        readStationCapability,
        writeStationCapability,
        getStationCapabilities,
    });
}
