import {
    handleSuccess,
    handleUserError,
} from '../../../../utils/handle-generic-notifications';
import { startTransaction } from '../../../../services/api/stations/v1/transactions.api';

const NOTIFICATION_DELAY = 5_000;

export default function getStartTransactionActions(api) {
    return {
        confirmStartTransaction({ commit }, { connector }) {
            commit('confirmOperation', { action: 'start', connector });
        },

        async startTransaction({ dispatch, state, commit }, tokenId) {
            commit('startCurrentOperation');

            try {
                await startTransaction(
                    state.stationId,
                    state.currentConnector.evseCode,
                    tokenId
                );
            } catch (error) {
                if (!error.status) {
                    throw error;
                }

                handleUserError(
                    'stations.components.connectorsControl.startTransactionFailed',
                    {
                        connectorId: state.currentConnector.code,
                        delay: NOTIFICATION_DELAY,
                    }
                );

                commit('finishCurrentOperation');
                await dispatch('getStation');

                return;
            }

            handleSuccess(
                'stations.components.connectorsControl.startStopTransactionAccepted',
                { delay: NOTIFICATION_DELAY }
            );

            await dispatch(
                'waitUntilTransactionAppears',
                state.currentConnector
            );
            commit('finishCurrentOperation');
            await dispatch('pollStation');
        },
    };
}
