import common from './common.mutations';
import station from './station.mutations';
import dashboard from './dashboard.mutations';
import connector from './connector.mutations';

export default {
    ...common,
    ...station,
    ...dashboard,
    ...connector,
};
