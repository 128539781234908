export const HEADER_X_FILENAME = 'x-file-name';
export const HEADER_CONTENT_TYPE = 'content-type';

/**
 * Triggers file download with provided blob in all supported platforms and browsers.
 *
 * @param {Blob} blob
 * @param {string} fileName
 * @returns {void}
 */
export function triggerFileDownload(blob, fileName) {
    let isSuccessful = false;

    // Check for dinosaurs
    if (window.navigator.msSaveOrOpenBlob) {
        isSuccessful = window.navigator.msSaveOrOpenBlob(blob, fileName);
    } else {
        const link = window.document.createElement('a');

        link.href = window.URL.createObjectURL(blob);
        link.download = fileName;
        isSuccessful = link.dispatchEvent(new MouseEvent('click'));
    }

    if (!isSuccessful) {
        throw new Error(`Failed to download file ${fileName}`);
    }
}

/**
 * Accepts a Response object that will be converted to a Blob that is being triggered as a download.
 *
 * @param {Response} response
 * @returns {Promise<void>}
 */
export async function downloadResponseAsFile(response) {
    const data = await response.blob();

    const fileName = response.headers.get(HEADER_X_FILENAME);
    const contentType = response.headers.get(HEADER_CONTENT_TYPE);

    const blob = new Blob([data], { type: contentType });

    return triggerFileDownload(blob, fileName);
}

export default {
    triggerFileDownload,
    downloadResponseAsFile,
};
