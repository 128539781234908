export default {
    /**
     * Updates state with station data such as its id and its evses
     * @param {Object} state
     * @param {Object} station
     */
    updateStationData(state, { station = {} }) {
        state.stationId = station.id;
        state.stationCode = station.code;
        state.stationAccountId = station.account?.id;
        state.evses = station.evses;
        state.chargingProfileName = station.chargingProfile
            ? station.chargingProfile.name
            : null;
    },

    /**
     * Updates state with available actions based on station capabilities and user permissions
     * @param {Object} state
     * @param {Object} data
     * @param {Object} data.capabilities
     * @param {Array} data.capabilities.iterable
     * @param {Array} data.permissions
     */
    updateActionAvailabilityChecks(
        state,
        { capabilities: { iterable = [] }, permissions = [] }
    ) {
        state.remoteStartStopTransactionAvailable =
            iterable.includes('REMOTE_START_STOP') &&
            permissions.includes('TRANSACTION:CREATE');
        state.unlockConnectorAvailable =
            iterable.includes('CONNECTOR_UNLOCK') &&
            permissions.includes('CONNECTOR:UNLOCK_CONNECTOR');
        state.resetEvseAvailable =
            iterable.includes('EVSE_RESET') &&
            permissions.includes('STATION:CONTROL_RESET');
        state.overrideMaxPowerAvailable =
            iterable.includes('CHARGING_PROFILE') &&
            permissions.includes('TRANSACTION:CREATE');
    },

    /**
     * Updates state with information required for starting transactions based on user permissions
     * @param {Object} state
     * @param {Array} permissions
     */
    updateStartTransactionData(state, permissions) {
        state.canEnterRawTokenForStartTransaction =
            permissions.includes('CARD:ENTER_RAW');
    },

    /**
     * Updates state with open transactions' information extended with the time the transaction was started at
     * @param {Object} state
     * @param {Array} openTransactions
     */
    updateOpenTransactionsData(state, openTransactions) {
        state.openTransactions = openTransactions.map((transaction) => ({
            ...transaction,
            startedAt: Date.now() - transaction.duration,
        }));
    },

    /**
     * Sets the state to show the confirmation modal for the specific operation to be run on the specific connector
     * @param {Object} state
     * @param {Object} payload
     * @param {string} payload.action
     * @param {Object} payload.connector
     */
    confirmOperation(state, { action, connector }) {
        state.confirmationInProgress = true;
        state.currentOperation = action;
        state.currentConnector = connector;
    },

    /**
     * Sets the state to hide the confirmation modal and represent an ongoing operation
     * @param {Object} state
     */
    startCurrentOperation(state) {
        state.confirmationInProgress = false;
        state.operationInProgress = true;
    },

    /**
     * Sets the state to represent no operation running on any connector
     * @param {Object} state
     */
    finishCurrentOperation(state) {
        state.currentConnector = null;
        state.currentOperation = null;
        state.operationInProgress = false;
    },

    /**
     * Updates state with patch object.
     * @param {Object} state
     * @param {Object} data
     */
    updateWithObject(state, data = {}) {
        Object.entries(data).forEach(([key, value]) => {
            state[key] = value;
        });
    },
};
