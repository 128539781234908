import getUnlockActions from './unlock';
import getStartTransactionActions from './start';
import getStopTransactionActions from './stop';
import getResetActions from './reset';
import getOverrideMaxPowerActions from './override';
import getAutostartActions from './autostart';
import getReadStationActions from './readStation';
import getReadTransactionActions from './readTransactions';

import { getAccountTokens } from '../../../../services/cards/cards.service';

export default function getActions(api) {
    return {
        setupStore({ commit }, { station, permissions, capabilities }) {
            commit('updateStationData', { station });
            commit('updateActionAvailabilityChecks', {
                capabilities,
                permissions,
            });
            commit('updateStartTransactionData', permissions);
        },

        async fetchActiveTokens({ state, commit }) {
            try {
                const tokensResponse = await getAccountTokens({
                    accountId: state.stationAccountId,
                    status: 'active',
                    limit: 1,
                });
                const stationAccountHasActiveTokens =
                    tokensResponse.content.length > 0;

                commit('updateWithObject', { stationAccountHasActiveTokens });
            } catch (error) {
                if (!error.status) {
                    throw error;
                }

                commit('updateWithObject', {
                    fetchingActiveTokensFailed: true,
                });
            }
        },

        cancelCurrentOperation({ commit }) {
            commit('finishCurrentOperation');
        },

        setLoading({ commit }, loading) {
            commit('updateWithObject', { operationInProgress: loading });
        },

        ...getUnlockActions(api),
        ...getResetActions(api),
        ...getStartTransactionActions(api),
        ...getStopTransactionActions(api),
        ...getOverrideMaxPowerActions(api),
        ...getAutostartActions(api),
        ...getReadStationActions(api),
        ...getReadTransactionActions(api),
    };
}
