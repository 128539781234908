import { hasAllPermissions, hasAnyPermission } from '../utils/permissions';
import { getters as auth } from '../services/auth.service';
import { get, set, remove } from '../services/local-store/index.js';
import { configuration } from '../services/config.service';
import userAccess from '../services/user-access.service.js';

export const DEFAULT_PUBLIC_ROUTE_NAME = 'login';
const defaultPrivateRoutes = Object.freeze({
    advancedInsightsDashboard: 'auth.sites.dashboard',
    stations: 'stations',
    branding: 'auth.branding',
    billingPlans: 'billing-plans-parent',
    profile: 'auth.profile',
});

const redirectStorageKey = 'redirectAfterLoginTo';

export const DEFAULT_FORBIDDEN_ROUTE_NAME = 'auth.forbidden';

// eslint-disable-next-line complexity
const getRedirectRoute = (to, baseUrl) => {
    if (to.meta?.noRedirect) {
        return to;
    }

    if (!configuration.tenant) {
        setRedirectStorageValue(to);

        return to;
    }

    if (!auth.isAuthenticated && !to.meta?.public) {
        setRedirectStorageValue(to);

        return getPublicDefaultRoute(to);
    }

    if (auth.isAuthenticated) {
        if (!auth.isSetupComplete) {
            return { name: 'auth.welcome' };
        }

        if (to.path === baseUrl) {
            return getPrivateDefaultRoute();
        }

        if (!hasAccessToRoute(to)) {
            remove(redirectStorageKey);

            return (
                to.meta?.redirect || {
                    name: DEFAULT_FORBIDDEN_ROUTE_NAME,
                }
            );
        }

        const previousRedirectRoute = get(redirectStorageKey);

        remove(redirectStorageKey);

        if (previousRedirectRoute?.name) {
            return previousRedirectRoute;
        }
    }

    if (auth.isAuthenticated && isUnavailableRoute(to)) {
        return getPrivateDefaultRoute();
    }

    return to;
};

function setRedirectStorageValue(to) {
    if (!to.name) {
        remove(redirectStorageKey);
    }

    if (!to.meta?.public && to.name !== defaultPrivateRoutes.stations) {
        // Make a copy of the item and remove matched to avoid cyclical error in localStore service
        const item = { ...to };

        item.matched = {};

        set(redirectStorageKey, item);
    }
}

const getPublicDefaultRoute = (to) => {
    const query =
        to.name !== DEFAULT_PUBLIC_ROUTE_NAME && to.name
            ? { redirect: to.name }
            : null;

    return { name: DEFAULT_PUBLIC_ROUTE_NAME, query };
};

/**
 * Returns an object with the name of the default private route based on the current logged-in user's access.
 * The list of possible private default routes ordered by priority is:
 *  - auth.sites.dashboard
 *  - stations
 *  - auth.branding
 *  - billing-plans-parent
 *  Those should cover all the cases, but profile route is used as fallback just in case, since every role has access to it.
 * @returns {Object} representing the route with shape {name: privateDefaultRoute}
 */
const getPrivateDefaultRoute = () => {
    if (userAccess.advancedInsights.read) {
        return { name: defaultPrivateRoutes.advancedInsightsDashboard };
    }

    if (userAccess.stations.view) {
        return { name: defaultPrivateRoutes.stations };
    }

    if (userAccess.branding.view) {
        return { name: defaultPrivateRoutes.branding };
    }

    if (userAccess.billingPlans.view) {
        return { name: defaultPrivateRoutes.billingPlans };
    }

    return { name: defaultPrivateRoutes.profile };
};

const isUnavailableRoute = (to) => {
    return (
        to.matched.length === 0 ||
        to.matched.some((record) => record.meta?.public)
    );
};

function getPermissionCheckMethod(meta) {
    const permissionsOperator = meta?.permissionsOperator;

    switch (permissionsOperator) {
        case 'OR':
            return hasAnyPermission;
        default:
            return hasAllPermissions;
    }
}

/**
 * Whether the current user has access to the given route
 *
 * Checks two things:
 * 1. Does the user have the required permissions?
 * 2. Is the route available?
 *
 * @param {Object} to
 * @returns {boolean}
 */
export function hasAccessToRoute(to) {
    // If a route has no special conditions, it's considered always available.
    const isAvailable = to.meta?.isAvailable ?? (() => true);

    return accessCheck(chooseResultingMeta(to)) && isAvailable();
}

/**
 * Determines what metadata should be used to define access.
 * ^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^
 * Since all the meta objects are merged by the Vue router when there are nested routes
 * (see https://router.vuejs.org/guide/advanced/meta.html)
 * we need to determine which metadata should be used to evaluate the access: hasAccess (new) or permissions (old).
 * We prefer the access method in last child metadata, or else we default to the merged meta.
 * TODO: Refactor after migrating out of permissions in meta.
 * @param {Object} to
 * @returns {Object} metadata object
 */
const chooseResultingMeta = (to) => {
    const lastChildMeta = to.matched?.at(-1)?.meta;
    const mergedMeta = to.meta;

    let resultMeta = mergedMeta;

    if (
        (lastChildMeta?.hasAccess &&
            typeof lastChildMeta?.hasAccess === 'function') ||
        lastChildMeta?.permissions
    ) {
        resultMeta = lastChildMeta;
    }

    return resultMeta;
};

/**
 * Determines what access check method should be used
 * ^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^
 * We implemented a new way of checking permissions, the hasAccess function,
 * but we still support the old way, the permissions array. We need to ensure backwards compatibility.
 * TODO: Remove after migrating out of permissions in meta.
 * @param {Object} meta the meta object
 * @returns {boolean} whether the user has permissions or not
 */
const accessCheck = (meta) => {
    // New way of checking access
    if (meta?.hasAccess && typeof meta.hasAccess === 'function') {
        return meta.hasAccess();
    }

    // Old way of checking access
    const permissions = meta?.permissions || [];
    const permissionCheckMethod = getPermissionCheckMethod(meta);

    return permissionCheckMethod(permissions);
};

export default getRedirectRoute;
