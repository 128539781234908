/**
 * Return the transaction for the EVSE of the given connector, if there is one in the given array of open transactions
 * @param {Array} openTransactions
 * @param {Object} connector
 * @returns {Object|undefined}
 */
export function findTransactionForEvse(openTransactions, connector) {
    return openTransactions.find(
        (transaction) => transaction.evseCode === Number(connector.evseCode)
    );
}

/**
 * Return whether the EVSE of the given connector has a transaction in the given array of open transactions
 * @param {Array} openTransactions
 * @param {Object} connector
 * @returns {boolean}
 */
export function evseHasTransaction(openTransactions, connector) {
    return findTransactionForEvse(openTransactions, connector) != null;
}

/**
 * Return whether the given connector has a transaction in the given array of open transactions
 * This function falsely assumes it will always be possible to match by EVSE ID. This may be solved in the follow-up of https://myevbox.atlassian.net/browse/EBH-1727
 * @param {Array} openTransactions
 * @param {Object} connector
 * @returns {boolean}
 */
export function connectorHasTransaction(openTransactions, connector) {
    const transactionsHaveConnectorCodes = openTransactions.every(
        (transaction) => transaction.connectorCode != null
    );

    if (!transactionsHaveConnectorCodes) {
        return evseHasTransaction(openTransactions, connector);
    }

    return openTransactions.some(
        (transaction) =>
            transaction.evseCode === Number(connector.evseCode) &&
            transaction.connectorCode === Number(connector.code)
    );
}
