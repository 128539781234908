export default {
    /**
     * @property {Object|null} currentConnector The connector running the ongoing operation
     * @property {string} currentConnector.id
     * @property {string} currentConnector.evseId
     * @property {string} currentConnector.code
     * @property {string} currentConnector.type
     * @property {string} currentConnector.availabilityStatus
     * @property {string} currentConnector.statusUpdatedAt
     */
    currentConnector: null,

    /**
     * @property {string|null} currentOperation The name of the current ongoing operation
     */
    currentOperation: null,

    /**
     * @property {boolean} operationInProgress Defines whether an operation is in progress or not
     */
    operationInProgress: false,
    /**
     * @property {boolean} confirmationInProgress Defines whether a confirmation modal is displayed or not
     */
    confirmationInProgress: false,

    /**
     * @property {string|null} stationId Everon's internal identifier of the station
     */
    stationId: null,

    /**
     * @property {string|null} stationCode The identity code of the station
     */
    stationCode: null,

    /**
     * @property {string|null} stationAccountId The identifier of the account that owns the station
     */
    stationAccountId: null,

    /**
     * @property {string|null} chargingProfileName Name of the charging profile applied to the station if any, `null` if not
     */
    chargingProfileName: null,

    /**
     * @property {boolean} canEnterRawTokenForStartTransaction Whether tokens are needed
     */
    canEnterRawTokenForStartTransaction: false,

    /**
     * @property {boolean} stationAccountHasActiveTokens Whether the station's account has active tokens
     */
    stationAccountHasActiveTokens: null,

    /**
     * @property {boolean} fetchingActiveTokensFailed Whether a call was made to fetch tokens for the station's account and failed
     */
    fetchingActiveTokensFailed: false,

    /**
     * @property {Array<evse>|null} evse The array of the station's EVSEs, as returned by the private station endpoint
     * @property {string} evses[].id The evse's UID in the Everon backend
     * @property {number} evses[].code The identifier that the station uses over OCPP to identify this EVSE
     * @property {Array<connector>} evses[].connectors The EVSE's connectors
     * @property {string} evses[].connectors[].id The connector's UID in the Everon backend
     * @property {number} evses[].connectors[].code The identifier that the station uses over OCPP to identify this connector (per EVSE)
     * @property {string} evses[].connectors[].availabilityStatus The connector's status upper-cased (i.e. "AVAILABLE", "CHARGING", etc.)
     * @property {Object} evses[].connectors[].specification The connector specification
     * @property {string} evses[].connectors[].specification.type Connector type (eg. "IEC_62196_T2", "CHADEMO", etc.)
     * @property {string} evses[].connectors[].specification.format "SOCKET" or "CABLE"
     * @property {string} evses[].connectors[].specification.powerType "AC_1_PHASE", "AC_2_PHASE", "AC_3_PHASE" or "DC"
     * @property {number} evses[].connectors[].specification.voltage Connector voltage
     * @property {number} evses[].connectors[].specification.amperage Connector amperage
     */
    evses: null,

    /**
     * @property {Array<transaction>} openTransactions Open transactions happening in the station
     * @property {string} transaction[].id
     * @property {number} transaction[].evseCode This is the EVSE's identity code (not it's UID)
     * @property {number} transaction[].connectorCode This is the connector's identity code (not it's UID)
     * @property {string} transaction[].tokenId
     * @property {string} transaction[].tokenType
     * @property {string} transaction[].contractId
     * @property {string} transaction[].duration Time in milliseconds that the transaction has been ongoing
     * @property {boolean} transaction[].startedAt Timestamp of the moment the transaction started at
     */
    openTransactions: [],

    /**
     * @property {boolean} remoteStartStopTransactionAvailable Defines whether remote start/stop transaction is available to be used
     */
    remoteStartStopTransactionAvailable: false,

    /**
     * @property {boolean} unlockConnectorAvailable Defines whether unlock connector is available to be used
     */
    unlockConnectorAvailable: false,

    /**
     * @property {boolean} resetEvseAvailable Defines whether reset EVSE is available to be used
     */
    resetEvseAvailable: false,

    /**
     * @property {boolean} overrideMaxPowerAvailable Defines whether override max power is available to be used
     */
    overrideMaxPowerAvailable: false,

    /**
     * @property {boolean|null} autostartEnabled Defines whether is supported and available
     */
    autostartEnabled: null,
};
