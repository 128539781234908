import dayjs from '../../services/wrappers/dayjs';

/**
 * Returns a humanized length of time, `2 days`, `a minute`, `4 months`, etc.
 *
 * The duration may be passed as a number of milliseconds, as an ISO 8601 duration string, or an object of various units:
 *
 * ```js
 * humanizeDuration(60_000) // "a minute"
 * humanizeDuration("P1Y2M3DT4H5M6S") // "a year"
 * humanizeDuration({
 *     seconds: 2,
 *     minutes: 2,
 *     hours: 2,
 *     days: 2,
 *     weeks: 2,
 *     months: 2,
 *     years: 2
 * }); // "2 years"
 * ```
 *
 * @param {number|Object|string} duration
 * @returns {string}
 */
export default function humanizeDuration(duration) {
    return dayjs.duration(duration).humanize();
}
