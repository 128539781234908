/**
 * Convert to camel case a given string
 * @param {string} text
 * @returns {string}
 */
export function toCamelCase(text) {
    if (!text) {
        return '';
    }

    return text
        .toLowerCase()
        .replace(/[-_]([a-z0-9])/gi, (_, $1) => $1.toUpperCase());
}

/**
 * Returns a transformer function who convert a string to a 'separator' case string.
 * Ex:
 *    createCaseTransformer('-')('helloWorld') // returns 'hello-world'
 *    createCaseTransformer('_')('helloWorld', true) // returns 'HELLO_WORLD'
 * @param {string} separator
 * @returns {Function}
 */
function createCaseTransformer(separator) {
    return (text, uppercase = false) => {
        if (!text) {
            return '';
        }

        const transformed = text
            .match(
                /[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g
            )
            .join(separator);

        return uppercase
            ? transformed.toUpperCase()
            : transformed.toLowerCase();
    };
}

/**
 * Convert to snake case a given string and optionally returns it as uppercased if uppercase = true
 * @param {string} text
 * @param {boolean} uppercase
 * @returns {string}
 */
export const toSnakeCase = createCaseTransformer('_');

/**
 * Convert to screaming snake case (SCREAMING_SNAKE_CASE) a given string
 * @param {string} text
 * @returns {string}
 */
export const toScreamingSnakeCase = (text) => toSnakeCase(text, true);

/**
 * Convert to kebab case a given string and optionally returns it as uppercased if uppercase = true
 * @param {string} text
 * @param {boolean} uppercase
 * @returns {string}
 */
export const toKebabCase = createCaseTransformer('-');

/**
 * Check if a string starts with the specified sequence. In case the first argument is null, undefined or not a string
 * the returned value will be false.
 * @param {string}text
 * @param {string}sequence
 * @returns {boolean}
 */
export function startsWith(text, sequence) {
    if (typeof text !== 'string' || typeof sequence !== 'string') {
        return false;
    }

    return text.startsWith(sequence);
}

/**
 * Returns a string with uppercased first letter and lowercased rest of the string
 * @param {string}string
 * @returns {string}
 */
export function capitalize(string) {
    if (typeof string !== 'string') {
        return '';
    }

    const [firstLetter, ...rest] = string;

    return `${firstLetter.toUpperCase()}${rest.join('').toLowerCase()}`;
}

/**
 * Returns a string with the percentage symbol
 * @param {string}string
 * @returns {string}
 */
export function formatToPercentage(string) {
    return `${string}%`;
}
