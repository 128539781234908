export default {
    accountFeatures: null,
    active: false,
    address: null,
    addressModel: null,
    availableSites: [],
    belongsToSite: false,
    countries: [],
    direction: '',
    directionModel: '',
    formIsValid: true,
    isAdvenirEnabledOnAccount: false,
    isAdvenirEnabledOnStation: false,
    isAdvenirEnabledOnStationModel: false,
    knowsAddress: true,
    geocoordinates: null,
    geocoordinatesModel: null,
    permissions: {},
    resolvingInfo: false,
    sameAccount: null,
    saveInProgress: false,
    selectedSiteId: null,
    site: null,
    siteSearchQuery: '',
    stationId: null,
    toggleSiteInProgress: false,
    placeholder: null,
};
