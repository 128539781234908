import {
    handleSuccess,
    handleUserError,
} from '../../../../utils/handle-generic-notifications';
import { sleep } from '../../../../utils/async';
import { stopTransaction } from '../../../../services/api/stations/v1/transactions.api';

const CONNECTOR_STATUS__FETCH_DELAY = 2 * 1000;
const NOTIFICATION_DELAY = 5_000;

export default function getStopTransactionActions() {
    return {
        confirmStopTransaction({ commit }, { connector }) {
            commit('confirmOperation', { action: 'stop', connector });
        },

        async stopTransaction({ commit, dispatch, state }) {
            commit('startCurrentOperation');

            const transaction = state.openTransactions.find(
                ({ evseCode }) =>
                    evseCode === Number(state.currentConnector.evseCode)
            );

            try {
                await stopTransaction(state.stationId, transaction.id);
            } catch (error) {
                if (!error.status) {
                    throw error;
                }

                handleUserError(
                    'stations.components.connectorsControl.stopTransactionFailed',
                    {
                        connectorId: state.currentConnector.code,
                        delay: NOTIFICATION_DELAY,
                    }
                );

                commit('finishCurrentOperation');
                await dispatch('getStation');

                return;
            }

            handleSuccess(
                'stations.components.connectorsControl.startStopTransactionAccepted',
                {
                    delay: NOTIFICATION_DELAY,
                }
            );

            await dispatch('waitUntilTransactionDisappears', transaction.id);
            commit('finishCurrentOperation');

            await sleep(CONNECTOR_STATUS__FETCH_DELAY);
            await dispatch('pollStation');
        },
    };
}
