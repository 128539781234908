<template>
    <div
        class="evo-fixed evo-top-0 evo-left-0 evo-w-full evo-z-50 evo-text-neutral-1"
        data-testing-id="notification-ribbon"
    >
        <transition name="slide-from-top">
            <p
                v-if="notification"
                class="evo-flex evo-p-l evo-pr-xxl evo-justify-center evo-items-center evo-font-bold evo-m-0 evo-bg-feedback-danger"
                :class="backgroundClass"
            >
                <jolt-icon
                    :icon-id="iconId"
                    class="evo-flex-shrink-0 evo-fill-current evo-w-6 evo-h-6 evo-mr-s"
                ></jolt-icon>
                {{ notification.text }}
                <span v-if="notification.link">
                    <router-link
                        :to="notification.link?.href"
                        class="evo-text-neutral-1 evo-font-semibold evo-inderline"
                    >
                        &nbsp;{{ $t(notification.link?.text) }}</router-link
                    >
                </span>
                <button
                    class="notification-ribbon__close evo-absolute evo-top-l evo-right-l"
                    @click="hideNotification"
                >
                    <jolt-icon
                        icon-id="icon-delete"
                        class="evo-fill-current evo-w-6 evo-h-6"
                    ></jolt-icon>
                </button>
            </p>
        </transition>
    </div>
</template>

<script>
import {
    computedNotification,
    hideNotification,
} from '../../services/notification.service';
import { NOTIFICATION_TYPES } from '../../core/constants';

export default {
    name: 'notification-ribbon',
    computed: {
        notification: computedNotification,
        backgroundClass() {
            if (this.notification?.type === NOTIFICATION_TYPES.ERROR) {
                return 'evo-bg-feedback-danger';
            }

            return 'evo-bg-feedback-success';
        },
        iconId() {
            return `icon-${
                this.notification?.type || NOTIFICATION_TYPES.SUCCESS
            }`.toLowerCase();
        },
    },
    methods: {
        hideNotification,
    },
};
</script>
<style scoped>
.notification-ribbon__close {
    background: transparent;
    border: 0;
    cursor: pointer;
}

.slide-from-top-enter-active,
.slide-from-top-leave-active {
    transition: transform 0.3s;
}

.slide-from-top-enter,
.slide-from-top-leave-to {
    transform: translateY(-100%);
}

:deep(.j-link:visited) {
    color: var(--evo-color-neutral-1);
}
</style>
