import { getFeatureFlagsForAccount } from '../account/account.service.js';
import {
    getSubscriptionType,
    getAssetSubscription,
} from '../subscription/subscription.service.js';
import { SUBSCRIPTIONS } from '../../core/constants';
import { handleError } from '../../utils/handle-generic-notifications';

/**
 * This file(and it usages) should be removed when the community charging feature is returned from the BE sometime in
 * the future.
 * The name of the file is a reminder for us (FE team) that we are leaking business rules into our domain which isn't
 * a good practice.
 */

export const isCommunityChargingEnabledForAssetBasedSubscriptionAccount =
    async (accountId, options = {}) => {
        try {
            const [resolvedAccountFlags, resolvedSubscriptionType] =
                await Promise.all([
                    loadAccountFlags(accountId, options),
                    loadSubscriptionType(accountId, options),
                ]);

            return (
                resolvedAccountFlags.communityCharging &&
                resolvedSubscriptionType === SUBSCRIPTIONS.TYPES.ASSET
            );
        } catch (e) {
            handleError(e);

            return false;
        }
    };

function loadSubscriptionType(accountId, options) {
    if (options.subscriptionType) {
        return options.subscriptionType;
    }

    return getSubscriptionType(accountId);
}

function loadAccountFlags(accountId, options) {
    return options.accountFlags || getFeatureFlagsForAccount(accountId);
}

export const isCommunityChargingEnabledForChargingFeatures = async (
    assetId,
    resourceType
) => {
    try {
        const { accountSubscriptionFeatures, assetSubscriptionFeatures } =
            await getAssetSubscription(assetId, resourceType);

        return (accountSubscriptionFeatures || assetSubscriptionFeatures)
            ?.communityCharging?.enabled;
    } catch (e) {
        handleError(e);
    }

    return false;
};

export default {
    isCommunityChargingEnabledForAssetBasedSubscriptionAccount,
    isCommunityChargingEnabledForChargingFeatures,
};
