import { enrichedConnectorsFromEvses } from '../../../modules/stations/stations.utils';
import { connectorHasTransaction } from './utils';

/**
 * Array with all action's name
 * @type {Array.<string>} actionsIterable
 */
const actionsIterable = [
    'override',
    'unlock',
    'start',
    'stop',
    'reset',
    'transactions',
];
const disabledStatus = {
    unlock: ['CHARGING'],
    start: ['OFFLINE', 'UNAVAILABLE', 'OUT_OF_ORDER'],
    stop: ['OFFLINE', 'UNAVAILABLE', 'OUT_OF_ORDER', 'RESERVED'],
};

export default {
    /**
     * Whether the connectors tile is loading
     * @param {Object} state
     * @param {boolean} state.operationInProgress
     * @returns {boolean}
     */
    isLoading({ operationInProgress }) {
        return operationInProgress;
    },

    /**
     * The station's connectors (including info about its specs and its EVSE)
     *
     * @param {Object} state
     * @returns {Array<EnrichedConnector>} See `connectorsEnriched` @ station.utils.js for a `typedef` of `EnrichedConnector`
     */
    connectors({ evses }) {
        return enrichedConnectorsFromEvses(evses).sort((a, b) =>
            a.evseCode > b.evseCode ? 1 : -1
        );
    },

    /**
     * Returns action objects per connector with store based data that will be used to generate the final action object
     * @param {Object} state
     * @param {Object} getters
     * @returns {Object}
     */
    getActionsConfig(state, getters) {
        const actionsConfig = {};

        getters.connectors.forEach((connector) => {
            actionsConfig[connector.id] = actionsIterable.map((name) => {
                const disabled =
                    getters.disabledActionResolver[connector.id][name];
                let spin =
                    getters.isLoading &&
                    state.currentConnector?.id === connector.id &&
                    state.currentOperation === name;

                if (name === 'start') {
                    spin = spin || state.autostartEnabled === null;
                }

                const included =
                    getters.includeActionResolver[connector.id][name];
                const tooltip =
                    getters.tooltipActionResolver[connector.id][name];

                return {
                    name,
                    disabled: spin || disabled,
                    spin,
                    included,
                    tooltip,
                };
            });
        });

        return actionsConfig;
    },

    /**
     * Returns action objects per connector with store based data that will be used to generate the final action object
     * @param {Object} state
     * @param {Object} getters
     * @returns {Object} with connector id as key and the action object as value
     */
    includeActionResolver(
        {
            openTransactions,
            remoteStartStopTransactionAvailable,
            unlockConnectorAvailable,
            resetEvseAvailable,
            overrideMaxPowerAvailable,
            chargingProfileName,
        },
        { connectors }
    ) {
        return connectors.reduce((result, connector) => {
            const hasTransaction = connectorHasTransaction(
                openTransactions,
                connector
            );

            result[connector.id] = {
                start: remoteStartStopTransactionAvailable && !hasTransaction,
                stop: remoteStartStopTransactionAvailable && hasTransaction,
                unlock: unlockConnectorAvailable,
                reset: resetEvseAvailable,
                override:
                    overrideMaxPowerAvailable &&
                    hasTransaction &&
                    chargingProfileName &&
                    ['CHARGING', 'OCCUPIED'].includes(
                        connector.availabilityStatus
                    ),
                transactions: true,
            };

            return result;
        }, {});
    },

    /**
     * Returns action objects per connector with store based data that enables or disables individual actions
     * @param {Object} state
     * @param {Object} getters
     * @returns {Object} with connector id as key and the action object as value
     */
    disabledActionResolver({ autostartEnabled }, { connectors, isLoading }) {
        return connectors.reduce((result, connector) => {
            result[connector.id] = {
                unlock:
                    isLoading ||
                    disabledStatus.unlock.includes(
                        connector.availabilityStatus
                    ),
                start:
                    isLoading ||
                    disabledStatus.start.includes(
                        connector.availabilityStatus
                    ) ||
                    autostartEnabled === true,
                stop:
                    isLoading ||
                    disabledStatus.stop.includes(connector.availabilityStatus),
                reset: isLoading,
                override: isLoading,
            };

            return result;
        }, {});
    },

    /**
     * Returns action objects per connector with store based data that describes which tooltip to show
     * @param {Object} state
     * @param {Object} getters
     * @returns {Object} With connector id as key and the action object as value. Contains only the actions for which the tooltip is based on store data.
     */
    tooltipActionResolver({ autostartEnabled }, { connectors }) {
        return connectors.reduce((result, connector) => {
            let startTooltip = 'startCharging';
            let unlockTooltip = 'unlockConnector';

            if (connector.availabilityStatus === 'CHARGING') {
                unlockTooltip = 'cantUnlockConnector';
            }

            if (connector.availabilityStatus === 'OFFLINE') {
                startTooltip = 'stationOffline';
            } else if (
                ['UNAVAILABLE', 'OUT_OF_ORDER'].includes(
                    connector.availabilityStatus
                )
            ) {
                startTooltip = 'stationNotOperating';
            } else if (autostartEnabled) {
                startTooltip = 'autostartEnabled';
            }

            result[connector.id] = {
                start: startTooltip,
                unlock: unlockTooltip,
            };

            return result;
        }, {});
    },
};
