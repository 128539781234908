import { reactive } from 'vue';

import { NOTIFICATION_TYPES } from '../core/constants';

export const notification = reactive({
    value: null,
});

export const computedNotification = () =>
    JSON.parse(JSON.stringify(notification.value));

export const showNotification = ({
    text,
    type = NOTIFICATION_TYPES.SUCCESS,
    time = 3000,
    link = null,
}) => {
    notification.value = { text, type, link };

    setTimeout(() => {
        notification.value = null;
    }, time);
};

export const hideNotification = () => (notification.value = null);
