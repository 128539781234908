/* eslint-disable max-len */
import apiClient from '../../../core/api/api-client';

/**
 * Returns an array of all stations
 * @param {Object} params
 * @param {string} [params.accountId] Filter stations for a specific account. This query parameter only applies to users with tenant level access. If the user has (sub-)account access, results are filtered according to the permissions passed in the authentication token.
 * @param {string} [params.siteId] Filter stations for a specific site. This query parameter only applies to users with tenant level access. If the user has (sub-)account access, results are filtered according to the permissions passed in the authentication token.
 * @param {boolean} [params.notAssignedToAnySite] Default value: `false`. Filter stations that do not belong to any site. (When true will override the siteId parameter)
 * @param {string} [params.code] Example: `EVB-P12345678`. Length ≤ 48. The station''s code. This is the identifier associated with the station, and is usually printed on the station, packaging, or invoice. In the user interface, this field is called station ID.
 * @param {string} [params.lifecycleState] The following values are allowed: `ACTIVE`, `PENDING`, `INACTIVE`, `DEACTIVATED`. Filter stations by their lifecycle state.
 * @param {string} [params.query] The query for full text search on various fields such as address or part of identity code.
 * @param {string} [params.connectivityStatus] The following values are allowed: `ONLINE`, `OFFLINE`. Filter stations by their connectivity state.
 * @param {number} [params.size] 1 ≤ value ≤ 200. Default value: `30`. Define the number of results per page.
 * @param {number} [params.page] 0 ≤ value. Default value: `0`. Specify the page number to see a certain set of results.
 * @returns {Promise<Array>}
 */
export function getStations(params = {}) {
    return apiClient.get('/api/stations/v1/', params);
}

/**
 * Imports a new station. Station status is `inactive`.
 * @param {string} stationId
 * @param {Object} requestData
 * @returns {Promise<JSON>}
 */
export function importStation(stationId, requestData) {
    return apiClient.post(`/api/stations/v1/${stationId}:import`, requestData);
}

/**
 * Update the details of a station. This uses `PATCH`, so you can submit the fields you'd like to change and
 * omit the ones you want to keep as they are.
 * @param {string} stationId
 * @param {Object} updates The fields that you would like to set
 * @returns {Object} The station's data
 */
export function updateStationDetails(stationId, updates) {
    return apiClient.patch(`/api/stations/v1/${stationId}`, updates);
}

/**
 * Update the location of a station. This uses `PATCH`, so you can submit the fields you'd like to change and
 * omit the ones you want to keep as they are.
 * @param {string} stationId
 * @param {Object} location The address or geocoordinates that must be used to lookup the location. One of address/gelocation must be provided.
 * @param {Object} [location.address]
 * @param {Object} [location.geocoordinates]
 * @param {string} [location.direction] The instructions to the customer on how to reach the station
 * @returns {Object} An object with `stationId` and `location` properties
 */
export function updateStationLocation(stationId, location) {
    return apiClient.patch(`/api/stations/v1/${stationId}/location`, location);
}

/**
 * Activates an inactive station
 * @param {string} stationIdentityCode Specify the stationCode (station ID in the user interface)
 * @param {string} accountId The unique identifier for the account in which to activate the station
 * @param {string} securityCode The station's security code
 * @param {Object} location The station''s location. The location object is required to activate a station, in which case you must provide either the station''s geo-coordinates, its address, or both.
 * @param {Object} [options] Optional parameters
 * @param {string} [options.reference] An optional field to give the station a name for quick reference purposes
 * @param {boolean} [options.advenir] Specify whether the station is part of the Advenir program. This is only possible if the account and station are located in France.
 * @param {Object} [options.subscription] Specify which subscription applies to the station
 * @returns {Promise<Object>}
 */
export function activateStation(
    stationIdentityCode,
    accountId,
    securityCode,
    location,
    options = {}
) {
    return apiClient.put(`/api/stations/v1/${stationIdentityCode}:activate`, {
        accountId,
        securityCode,
        location,
        ...options,
    });
}
activateStation.ERROR_CODES = {
    STATION_NOT_IMPORTED: 'SM_ACTIVATION_STATION_NOT_IMPORTED_ERROR',
    STATION_IS_ALREADY_ACTIVE: 'SM_ACTIVATION_STATION_IS_ALREADY_ACTIVE_ERROR',
    STATION_NOT_ALLOWED_FOR_SUBSCRIPTION: 'SM_ACTIVATION_SUBSCRIPTION_ERROR',
};

/**
 * Deactivates a station based on its unique identifier
 * @param {string} stationId
 * @returns {Promise.<Object>}
 */
export function deactivateStation(stationId) {
    return apiClient.put(`/api/stations/v1/${stationId}:deactivate`);
}
deactivateStation.ERROR_CODES = {
    PAYTER_DETECTED: 'SM_STATION_WITH_PAYTER_TERMINAL_DEACTIVATION_ERROR',
};

/**
 * Deletes a station from the storage based on its unique identifier. This only works for stations with an `inactive` or `deactivated` lifecycle status. You can't delete an active station.
 * @param {string} stationId
 * @returns {Promise.<Object>}
 */
export function deleteStation(stationId) {
    return apiClient.delete(`/api/stations/v1/${stationId}`);
}

/**
 * Returns a single station by a given id using the public API
 * @param {string} id
 * @returns {Promise.<Object>}
 */
export function getStation(id) {
    return apiClient.get(`/api/stations/v1/${id}`);
}

/**
 * Resets the station identified by stationId using public API
 * @param {string} stationId
 * @returns {Promise}
 */
export function resetStation(stationId) {
    return apiClient.post(`/api/stations/v1/${stationId}:reset`);
}

/**
 * Returns one or more counts of the number of stations matching the given criteria
 * Verifies if station with a given identity code exists and if it has an owner
 * @param {string} identityCode
 * @returns {Promise.<Object>}
 */
export function verifyStation(identityCode) {
    return apiClient.get(
        `/api/stations/v1/${identityCode}/is-eligible-to-activate`
    );
}

/**
 * Verifies if station security code is valid
 * @param {string} identityCode
 * @param {string} securityCode
 * @returns {Promise.<Object>}
 */
export function verifySecurityCode(identityCode, securityCode) {
    return apiClient.get(
        `/api/stations/v1/${identityCode}/is-eligible-to-activate`,
        { securityCode }
    );
}

/**
 * Returns one or more counts of the number of stations matching the given criteria
 *
 * If `groupBy` is specified, the endpoint returns an object with the group names as keys. Otherwise, the returned object has a single property `TOTAL`.
 *
 * @param {Object} params
 * @param {string} params.accountId The unique identifier for the account in which the stations are activated
 * @param {string} params.lifecycleState The lifecycle state of the stations. Possible values are `"ACTIVE"`, `"INACTIVE"` and `"DEACTIVATED"`
 * @param {string} params.connectivityStatus Whether the stations are online or not. Possible values are `"ONLINE"` and `"OFFLINE"`
 * @param {string} params.groupBy When given, will cause the count of stations to be grouped by the given value. Only possible value, for now, is `"LIFECYCLE_STATE"`
 * @returns {Promise.<Object>}
 */
export function countStations(params) {
    return apiClient.get('/api/stations/v1/count', params);
}

/**
 * Returns one or more counts of the number of connectors matching the given criteria
 *
 * If `groupBy` is specified, the endpoint returns an object with the group names as keys. Otherwise, the returned object has a single property `TOTAL`.
 *
 * @param {Object} params
 * @param {string} params.accountId The unique identifier for the account in which the connector's stations are activated
 * @param {string} params.lifecycleState The lifecycle state of the connector's stations. Possible values are `"ACTIVE"`, `"INACTIVE"` and `"DEACTIVATED"`
 * @param {string} params.connectivityStatus Whether the connector's stations are online or not. Possible values are `"ONLINE"` and `"OFFLINE"`
 * @param {string} params.groupBy When given, will cause the count of connectors to be grouped by the given value. Possible values are `"CONNECTOR_TYPE"`, `"AVAILABILITY_STATUS"` and `"POWER_TYPE"`
 * @returns {Promise.<Object>}
 */
export function countConnectors(params) {
    return apiClient.get('/api/stations/v1/connectors/count', params);
}

const exports = {
    getStations,
    importStation,
    activateStation,
    deactivateStation,
    deleteStation,
    getStation,
    resetStation,
    updateStationDetails,
    updateStationLocation,
    verifyStation,
    verifySecurityCode,
    countStations,
    countConnectors,
};

export default exports;
