import { createAutostartDataSource } from '../../../../services/api/stations/v1/capabilities/capabilities.api';

export default function getAutostartActions(api) {
    return {
        async loadAutostartDataSource({ commit, state }) {
            try {
                const dataSource = await createAutostartDataSource(
                    state.stationId
                ).load({ silent: true });

                commit('updateWithObject', {
                    autostartEnabled: dataSource.isEnabled,
                });
            } catch (error) {
                if (!error.status) {
                    throw error;
                }
            } finally {
                if (!state.autostartEnabled) {
                    commit('updateWithObject', {
                        autostartEnabled: false,
                    });
                }
            }
        },
    };
}
