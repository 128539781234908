import apiClient from '../../../../core/api/api-client';

/**
 * Returns an array of open transactions for a given station
 * @param {string} stationId
 * @returns {Promise.<Array>}
 */
export function getOpenTransactions(stationId) {
    return apiClient.get(`/api/stations/v1/${stationId}/transactions`);
}

/**
 * Remotely starts a transaction
 * @param {string} stationId
 * @param {string} evseCode Identity code of the EVSE in which the transaction will start
 * @param {string} tokenId RFID of the token used to start the transaction
 * @returns {Promise}
 */
export function startTransaction(stationId, evseCode, tokenId) {
    return apiClient.post(`/api/stations/v1/${stationId}/transactions:start`, {
        evseCode,
        idTag: tokenId,
    });
}

/**
 * Remotely stops an open transaction
 * @param {string} stationId
 * @param {string} transactionId
 * @returns {Promise}
 */
export function stopTransaction(stationId, transactionId) {
    return apiClient.post(
        `/api/stations/v1/${stationId}/transactions/${transactionId}:stop`
    );
}

export default function transactions() {
    return {
        getOpenTransactions,
        startTransaction,
        stopTransaction,
    };
}
