import { reactive } from 'vue';

const AUTOSTART_WITH_TOKEN = 'AUTOSTART_WITH_TOKEN';
const OCPP_AUTOSTART = 'AUTO_START';

/**
 * This class is the representation of the API/DB persistent state for the autostart feature.
 * It's also in charge of modifying that state only updating the local store when a confirmation of the change was
 * received from the API.
 */
class StationAutostartDataSource {
    /**
     * Initialise the instance with the stationId specified.
     * @param {string} stationId the station from which the autostart info will be loaded
     * @param {Object} api object with the methods:
     * @param {Function} api.getStationCapabilities(stationId)
     * @param {Function} api.readStationCapability(stationId, 'AUTO_START' | 'AUTOSTART_WITH_TOKEN')
     * @param {Function} api.writeStationCapability(stationId, 'AUTO_START' | 'AUTOSTART_WITH_TOKEN', {Object} data)
     */
    constructor(stationId, api) {
        this._stationId = stationId;
        this._api = api;

        this._store = reactive({
            isEnabled: null,
            isSupported: null,
            usesToken: null,
            token: null,
            loadingInProgress: false,
            saveInProgress: false,
            loadingError: null,
            savingError: null,
        });

        Object.keys(this._store).forEach((field) => {
            Object.defineProperty(this, field, {
                get() {
                    return this._store[field];
                },
            });
        });
    }

    async load(options = { silent: false }) {
        this._store.loadingInProgress = true;

        try {
            const capabilities = await this._api.getStationCapabilities(
                this.stationId
            );

            this._store.isSupported = [
                AUTOSTART_WITH_TOKEN,
                OCPP_AUTOSTART,
            ].some((token) => capabilities[token]);

            if (this._store.isSupported) {
                this._store.usesToken = AUTOSTART_WITH_TOKEN in capabilities;

                await this._loadCapabilityInfo();
            }

            this._store.loadingError = null;
        } catch (error) {
            this._store.loadingError = error;
            this._propagateError(error, options);
        } finally {
            this._store.loadingInProgress = false;
        }

        return this;
    }

    /**
     * Loads whether autostart is enabled and the token.
     * @returns {Promise<boolean>}
     */
    async _loadCapabilityInfo() {
        const { enabled, token } = await this._api.readStationCapability(
            this.stationId,
            this._getFeatureToken()
        );

        this._store.isEnabled = enabled === 'true';
        this._store.token = token;
    }

    enableAutostart(token, options) {
        return this.save({ enabled: true, token }, options);
    }

    disableAutostart(options) {
        return this.save({ enabled: false }, options);
    }

    /**
     * Save whether autostart is enabled.
     * @param {Object} data has the following fields {isEnabled: Boolean, [token: String]}
     * @param {Object} options has the following fields:
     *               {boolean} silent: indicates if this method should propagate any error
     * @returns {Promise<void>}
     */
    async save({ enabled, token }, options = { silent: false }) {
        if (this._store.isEnabled === enabled) {
            return;
        }

        this._store.saveInProgress = true;

        try {
            const response = await this._api.writeStationCapability(
                this.stationId,
                this._getFeatureToken(),
                {
                    enabled: enabled.toString(),
                    token: token || this._store.token,
                }
            );

            this._store.isEnabled = response.enabled === 'true';
            this._store.token = response.token;
            this._store.savingError = null;
        } catch (error) {
            this._store.savingError = error;
            this._propagateError(error, options);
        } finally {
            this._store.saveInProgress = false;
        }
    }

    _getFeatureToken() {
        return this._store.usesToken ? AUTOSTART_WITH_TOKEN : OCPP_AUTOSTART;
    }

    get stationId() {
        return this._stationId;
    }

    get requestInProgress() {
        return this._store.loadingInProgress || this._store.saveInProgress;
    }

    _propagateError(error, options) {
        if (!options.silent || !('status' in error)) {
            throw error;
        }
    }
}

export default StationAutostartDataSource;
