import forever from './forever';

/**
 * Creates a promise that resolves after a specified time.
 *
 * Example usage:
 *
 * ```javascript
 * await sleep(2 * 1000);
 * ```
 *
 * With an argument:
 *
 * ```javascript
 * const foo = await sleep(2 * 1000, 'foo');
 * ```
 *
 * @param {number} [milliseconds] How long to wait to resolve, in milliseconds
 * @param {any} [argument] The promise will resolve with an array of all the arguments after the first one
 * @param {CancelToken} [cancelToken] Token with which the sleep can be cancelled
 * @returns {Promise}
 */
export default function sleep(milliseconds, argument, cancelToken) {
    if (cancelToken?.isCancelled) {
        return forever();
    }

    let timeoutId;

    const sleeping = new Promise((resolve) => {
        timeoutId = setTimeout(resolve, milliseconds, argument);
    });

    cancelToken?.onCancel(() => {
        clearTimeout(timeoutId);
    });

    return sleeping;
}
