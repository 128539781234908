import {
    hasAllPermissions,
    hasAnyPermission,
    hasPermission,
} from '../utils/permissions.js';
import { state as advancedInsightsState } from './mixins/advanced-insights-mixin.service.js';
import { getters as authGetters } from './auth.service.js';
import {
    subscriptionState,
    getters as subscriptionGetters,
} from './subscription/subscription.service.js';
import { getters as tenantSettingsGetters } from './tenant-settings/tenant-settings.service.js';
import { SUBSCRIPTIONS } from '../core/constants.js';

// eslint-disable-next-line complexity
const canSeeDiscountGroups = () => {
    if (!hasPermission('TARIFF_DISCOUNT_GROUP:READ')) {
        return false;
    }

    const isCustomOffering =
        subscriptionState.subscriptionType ===
        SUBSCRIPTIONS.TYPES.CUSTOM_OFFERING;
    const hasPublicCharging =
        subscriptionState.subscription?.accountSubscription?.features
            ?.stationPublicCharging?.enabled;

    if (isCustomOffering && !hasPublicCharging) {
        return false;
    }

    if (
        !tenantSettingsGetters.isTenantAccountBased ||
        (!authGetters.isTenantLevelUser &&
            !isCustomOffering &&
            !subscriptionGetters.isAccountBasedSubscription)
    ) {
        return false;
    }

    return true;
};

const hasDiscountGroups = () => {
    if (!canSeeDiscountGroups()) {
        return false;
    }

    return (
        authGetters.isTenantLevelUser ||
        Boolean(
            subscriptionState.subscription?.accountSubscription?.features
                ?.stationPublicCharging?.enabled
        )
    );
};

export default {
    get advancedInsights() {
        return {
            view: advancedInsightsState.canSeeAdvancedInsights,
            read: advancedInsightsState.hasAdvancedInsights,
        };
    },

    get advancedInsightsUpsell() {
        return {
            read: !advancedInsightsState.hasAdvancedInsights,
        };
    },

    get cards() {
        return {
            view: hasPermission('CARD:READ'),
        };
    },

    get users() {
        return {
            view: hasAnyPermission([
                'SYSTEM_USER:READ_ALL',
                'CUSTOMER:READ_ALL',
                'USER_GROUP:READ_ALL',
            ]),
        };
    },

    get stations() {
        return {
            view: hasPermission('STATION:READ'),
        };
    },

    get stationsDashboard() {
        return {
            view: hasPermission('STATION:DASHBOARD_READ'),
        };
    },

    get chargingProfiles() {
        return {
            view: hasPermission('CHARGING_PROFILE:READ'),
        };
    },

    get discounts() {
        return {
            view: canSeeDiscountGroups(),
            read: hasDiscountGroups(),
        };
    },

    get discountsUpsell() {
        return {
            read: !this.discounts.read,
        };
    },

    // Accounts

    /**
     * Account grants access to most basic level of accounts (own account),
     * for child account access use accounts() instead.
     */
    get account() {
        return {
            view: hasPermission('ACCOUNT:READ'),
        };
    },

    /**
     * Accounts grants access to parent accounts level and above, to see other accounts.
     * for own account only access user get account() instead.
     */
    get accounts() {
        return {
            view: hasAnyPermission([
                'ACCOUNT:READ_PERSONAL_INFO',
                'ACCOUNT:READ_ALL',
            ]),
        };
    },

    /**
     * myAccount grants access to the navigation items that are only available for account based parent subscriptions,
     * Not suitable for the my account route '/account' that is accessible also for sub accounts and assets accounts.
     */
    get myAccount() {
        return {
            view:
                this.accounts.view &&
                subscriptionGetters.isAccountBasedSubscription &&
                tenantSettingsGetters.isTenantAccountBased,
        };
    },

    /**
     * subAccounts grants access to the subAccounts menu, and is only available for account based parent subscriptions.
     */
    get subAccounts() {
        return {
            view: subscriptionState.subscription?.accountSubscription?.features?.templateFeatures?.some(
                (feature) =>
                    feature.name ===
                        SUBSCRIPTIONS.FEATURES.SUB_ACCOUNT_CREATION &&
                    feature.enabled === true
            ),
        };
    },

    get customOffering() {
        return {
            view: hasPermission('ACCOUNT:CREATE_CUSTOM_OFFERING'),
        };
    },

    get onboardingOnBehalf() {
        return {
            view: hasPermission('ACCOUNT:CREATE'),
        };
    },

    get accountSubscription() {
        return {
            view: hasAllPermissions([
                'ACCOUNT:ONBOARD_ON_BEHALF',
                'ACCOUNT:READ_PERSONAL_INFO',
            ]),
        };
    },

    get accountBillingInformation() {
        return {
            view: hasAllPermissions([
                'ACCOUNT:UPDATE_ALL',
                'ACCOUNT:READ_PERSONAL_INFO',
            ]),
        };
    },

    get accountBillingHistory() {
        return {
            view: hasAllPermissions([
                'INVOICE:READ_ALL',
                'ACCOUNT:READ_PERSONAL_INFO',
            ]),
        };
    },

    get accountTransactions() {
        return {
            view: hasAllPermissions([
                'INVOICE:READ_ALL',
                'ACCOUNT:READ_PERSONAL_INFO',
            ]),
        };
    },

    get accountFeatures() {
        return {
            view: hasPermission('ACCOUNT:UPDATE_ALL'),
        };
    },

    get accountCostCalculation() {
        return {
            view: hasPermission('ACCOUNT:UPDATE_REIMBURSEMENT_RATE'),
        };
    },

    get accountReimbursement() {
        return {
            view: hasPermission('ACCOUNT:UPDATE_REIMBURSEMENT_RATE'),
        };
    },

    get accountPayout() {
        return {
            view: hasPermission('ACCOUNT:UPDATE_REIMBURSEMENT_RATE'),
        };
    },

    // tenant specific routes

    get tenantSection() {
        return {
            view: hasAnyPermission([
                'TENANT:UPDATE',
                'BRANDING:UPDATE',
                'BILLING_PLAN:READ',
            ]),
        };
    },

    get tenantSettings() {
        return {
            view: hasPermission('TENANT:UPDATE'),
        };
    },

    get parkedBilling() {
        return {
            view:
                hasPermission('BILLING_PARKING:MANAGE') &&
                tenantSettingsGetters.tenantCanDoBillingManagement,
        };
    },

    get tenantSettingsLanguages() {
        return {
            view: hasPermission('TENANT:UPDATE'),
        };
    },

    get tenantSettingsCompaniesList() {
        return {
            view: hasPermission('TENANT:UPDATE'),
        };
    },

    get tenantSettingsCountriesList() {
        return {
            view: hasPermission('TENANT:UPDATE'),
        };
    },

    get tenantSettingsSupportLinks() {
        return {
            view: hasPermission('TENANT:UPDATE'),
        };
    },

    get tenantSettingsNotifications() {
        return {
            view: hasPermission('TENANT:UPDATE'),
        };
    },

    get branding() {
        return {
            view: hasPermission('BRANDING:UPDATE'),
        };
    },

    get billingPlans() {
        return {
            view: hasPermission('BILLING_PLAN:READ'),
        };
    },

    get billingPlansProducts() {
        return {
            view: hasPermission('BILLING_PLAN:READ'),
        };
    },

    get billingPlansPlans() {
        return {
            view: hasPermission('BILLING_PLAN:READ'),
        };
    },
};
