import { isCommunityChargingEnabledForAssetBasedSubscriptionAccount } from '../business-rules/business-rules.service.js';

export const addCommunityChargingToProductsCharacteristics = async (
    products,
    accountId,
    options
) => {
    const isEnabled =
        await isCommunityChargingEnabledForAssetBasedSubscriptionAccount(
            accountId,
            options
        );

    if (isEnabled) {
        return products.map(({ characteristics = [], ...rest }) => {
            return {
                ...rest,
                characteristics: [
                    {
                        name: 'communityCharging',
                        enabled: true,
                        value: null,
                    },
                    ...characteristics,
                ],
            };
        });
    }

    return products;
};

export const addCommunityChargingToProductCharacteristics = async (
    product,
    accountId,
    options
) => {
    if (!product) {
        return product;
    }

    const [productTransformed] =
        await addCommunityChargingToProductsCharacteristics(
            [product],
            accountId,
            options
        );

    return productTransformed;
};

export default {
    addCommunityChargingToProductsCharacteristics,
    addCommunityChargingToProductCharacteristics,
};
