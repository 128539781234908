function getSelectedAccount(state) {
    const { queryParams } = state;

    if (queryParams.account) {
        const parsedAccount = JSON.parse(window.atob(queryParams.account));

        return {
            id: parsedAccount.value,
            name: parsedAccount.label,
        };
    }

    return null;
}

export default {
    selectedAccountName(state) {
        return getSelectedAccount(state)?.name || null;
    },

    selectedAccountId(state) {
        return getSelectedAccount(state)?.id || null;
    },

    selectedAccount(state) {
        return getSelectedAccount(state);
    },
};
