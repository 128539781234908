import apiClient from '../../../../core/api/api-client';

/**
 * Returns the site with the given siteId
 * @param {string} siteId
 * @returns {Promise.<Object>}
 */
export function getSite(siteId) {
    return apiClient.get(`/api/stations/v1/sites/${siteId}`);
}

/**
 * Creates a new site
 * @param {Object} siteDto
 * @returns {Promise}
 */
export function createSite(siteDto) {
    return apiClient.post('/api/stations/v1/sites', siteDto);
}

/**
 * Updates a site
 * @param {string} siteId
 * @param {Object} siteDto
 * @returns {Promise}
 */
export function updateSite(siteId, siteDto) {
    return apiClient.put(`/api/stations/v1/sites/${siteId}`, siteDto);
}

/**
 * Deletes a site
 * @param {string} siteId
 * @returns {Promise}
 */
export function deleteSite(siteId) {
    return apiClient.delete(`/api/stations/v1/sites/${siteId}`);
}

/**
 * Gets the list of available charging profiles the specified station can be assigned to, which are the sites of the account that the station belongs to plus the predefined by
 * its tenant.
 * @param {string} stationId
 * @returns {Promise}
 */
export function getAvailableSitesForStation(stationId) {
    return apiClient.get(`/api/stations/v1/${stationId}/available-sites`);
}

/**
 * Set or remove the site associated with the specified station
 * @param {string} stationId The id of the station for which to update the site association
 * @param {string} siteId The site to be assigned to the station. If null, the station will be unassigned from the site
 * @returns {Promise<{stationId: string, siteId: string}>}
 */
export function updateSiteAssociation(stationId, siteId) {
    return apiClient.put(`/api/stations/v1/${stationId}/site`, { siteId });
}

export default function sites() {
    return {
        getSite,
        createSite,
        updateSite,
        deleteSite,
        getAvailableSitesForStation,
        updateSiteAssociation,
    };
}
