class ApiError extends Error {
    constructor({ errorCode, message, status, nestedError }) {
        super(message);
        this.errorCode = errorCode;
        this.status = status;
        this.nestedError = nestedError;
    }
}

/**
 * Parses the response body to JSON
 * @param {Response} response
 * @returns {Promise<*|{ok}>}
 */
export async function parseJSON(response) {
    const contentType = response?.headers?.get('content-type');
    const isJson = contentType && contentType.includes('application/json');

    if (!response.ok) {
        if (isJson) {
            const {
                errorCode,
                messages,
                message,
                error: nestedError,
            } = await response.json();

            throw new ApiError({
                errorCode,
                message: message ?? messages?.join(','),
                status: response.status,
                nestedError,
            });
        }

        const errorText = await response.text();

        throw new Error(errorText);
    } else {
        if (isJson) {
            return response.json();
        }

        return response;
    }
}
