/**
 * This file contains the charging-profile endpoints.
 * Wrapper function receives all the dependencies as arguments.
 * Return object contains alphabetically sorted methods.
 * @returns {Object}
 */

import apiClient from '../../../../core/api/api-client';

/**
 * Get all charging profiles visible to the current user
 * @param {Object} params - Query params
 * @param {string} params.accountId
 * @param {string} params.name name of charging profile
 * @param {string} params.clientType type of client WEB or MOBILE
 * @returns {Promise.Object} Object of charging profiles grouped by ownership level
 */
export function getChargingProfiles(params) {
    return apiClient.get('/api/stations/v1/charging-profiles', params);
}

/**
 * Sets the station's charging profile
 * @param {string} id - The id of the station which charging profile information is going to be updated
 * @param {string} chargingProfileId - The id of the charging profile the station is going to be assigned to, `null` when un-assigning
 * @returns {Promise}
 */
export function setChargingProfile(id, chargingProfileId) {
    return apiClient.put(`/api/stations/v1/${id}/charging-profile:assign`, {
        id: chargingProfileId,
    });
}

/**
 * Creates a new charging profile
 * @param {Object} params
 * @returns {Promise}
 */
export function createChargingProfile(params) {
    return apiClient.post('/api/stations/v1/charging-profiles', params);
}

/**
 * Updates a charging profile
 * @param {string} id
 * @param {Object} params
 * @returns {Promise}
 */
export function updateChargingProfile(id, params) {
    return apiClient.put(`/api/stations/v1/charging-profiles/${id}`, params);
}

/**
 * Attempts to delete the specified charging profile.
 * It will fail with a 409 HTTP error status if the charging profile is assigned to any station.
 * @param {string} id
 * @returns {Promise}
 */
export function deleteChargingProfile(id) {
    return apiClient.delete(`/api/stations/v1/charging-profiles/${id}`);
}

/**
 * Get a charging profile based on its id
 * @param {string} profileId - profile id
 * @returns {Promise} Charging profile
 */
export function getChargingProfile(profileId) {
    return apiClient.get(`/api/stations/v1/charging-profiles/${profileId}`);
}

/**
 * Overrides charging profile's scheduled max current to use station's max current
 * @param {Object} params
 * @property {string} stationId the station's id
 * @property {string} connectorIdentityCode the EVSE's identity code - not the connector's!
 * @property {string} transactionId the transaction id
 * @returns {Promise}
 */
export function chargeWithMaxPower({ stationId, evseCode, transactionId }) {
    return apiClient.post(
        `/api/stations/v1/${stationId}/charge-with-max-power`,
        {
            evseCode,
            transactionId,
        }
    );
}
