import { STATION_TYPES } from '../../../core/constants';

function generateTile(type) {
    const { TITLE, ICON, TOOLTIP } = STATION_TYPES[type.toUpperCase()];

    return {
        type,
        id: `tile-${type}`,
        url: `#${type}`,
        count: null,
        isLoading: false,
        title: TITLE,
        icon: ICON,
        tooltip: TOOLTIP,
    };
}

export default function () {
    return {
        currentUserProfile: null,
        fetchingStations: false,
        accountsSuggestionList: [],
        stations: [],
        tiles: ['online', 'offline', 'inactive'].map(generateTile),
        pagination: {
            size: 0,
        },
        /**
         * type LoadError{
         *    data: string
         *    status: number
         *    statusText: string
         * }
         */
        stationsLoadError: null,
        selectedView: null,
        selectedStationsStatusFilter: null,
        selectedConnectorsStatusesFilter: null,
        queryParams: {},
        connectorsData: {
            types: [],
            statuses: [],
            isConnectorStatusesLoading: true,
            isConnectorTypesLoading: true,
        },
    };
}
