import { executeStationCapability } from '../../../../services/api/stations/v1/capabilities/capabilities.api';
import {
    handleSuccess,
    handleUserError,
} from '../../../../utils/handle-generic-notifications';

const unlockCapabilityName = 'CONNECTOR_UNLOCK';

export default function getUnlockActions() {
    return {
        confirmUnlockConnector({ commit }, { connector }) {
            commit('confirmOperation', { action: 'unlock', connector });
        },

        async unlockConnector({ dispatch, state, commit }) {
            commit('startCurrentOperation');

            try {
                await executeStationCapability(
                    state.stationId,
                    unlockCapabilityName,
                    {
                        evseCode: state.currentConnector.evseCode,
                        connectorCode: state.currentConnector.code,
                    }
                );

                handleSuccess(
                    'stations.components.connectorsControl.unlockConnectorAccepted',
                    {
                        connectorId: state.currentConnector.code,
                    }
                );
            } catch (error) {
                if (!error.status) {
                    throw error;
                }

                handleUserError(
                    'stations.components.connectorsControl.unlockConnectorFailed',
                    {
                        connectorId: state.currentConnector.code,
                    }
                );
            } finally {
                commit('finishCurrentOperation');
                await dispatch('getStation');
            }
        },
    };
}
