import dayjs from '../wrappers/dayjs';
import {
    humanizeDuration,
    formatValuesInTimeZone,
    endOfToday,
    startOfMonth,
    subMonths,
} from '../../utils/date';
import {
    downloadResponseAsFile,
    HEADER_X_FILENAME,
} from '../../services/file/file.service';
import apiClient from '../../core/api/api-client';
import { getters } from '../../services/config.service';

const formatValuesInUtc = (obj) => formatValuesInTimeZone(obj, 'UTC');

/**
 * Returns processed locale code to be sent to BE from browser locale
 * When browserLocale consist of language-locale return browserLocale
 * When browserLocale consist only of language:
 *  * If language is in `asymmetricalLangLocale` gets locale from `asymmetricalLangLocale`
 *  * If language is `no` returns `nb-NO`
 *  * Otherwise return `xx-XX` code, where `XX` is language `xx` to uppercase
 * @param {string} browserLocale
 * @returns {string}
 */
function processBrowserLocale(browserLocale) {
    const asymmetricalLangLocale = {
        cs: 'CZ',
        da: 'DK',
        el: 'GR',
        nb: 'NO',
        sv: 'SE',
        en: 'GB',
    };
    const [language, locale] = browserLocale.split('-');

    switch (true) {
        case Boolean(locale):
            return browserLocale;
        case Object.keys(asymmetricalLangLocale).includes(language):
            return `${language}-${asymmetricalLangLocale[language]}`;
        case language === 'no':
            return 'nb-NO';
        default:
            return `${language}-${language.toUpperCase()}`;
    }
}

/**
 * Returns generated PDF invoice
 * @param {string} transactionsType
 * @param {string} id
 * @param {Object} range
 * @param {number} evseId
 * @returns {Promise}
 */
function fetchTransactionsPdf(
    transactionsType,
    { id, range, evseId, language }
) {
    const { startDate, endDate } = formatValuesInUtc(range);
    const transactionReportUrl = `/api/transactions/${transactionsType}/${id}/report`;
    const fileName = `${evseId ? `${evseId}_` : ''}${startDate.substring(
        0,
        10
    )}_${endDate.substring(0, 10)}`;
    const params = {
        // The transactions report API takes an `evseId` using the name `connectorId`
        connectorId: evseId,
        startDate,
        endDate,
        browser_locale: language,
    };

    // remove undefined params
    Object.keys(params).forEach(
        (key) => params[key] === undefined && delete params[key]
    );

    return (
        apiClient
            .goFetch(transactionReportUrl, {
                headers: {
                    accept: 'application/pdf',
                },
                params,
            })
            // We have to do this because the API endpoint does
            // not return the correct x-file-name header
            // oddly enough, it works fine for the CSV parameter
            .then(
                (response) =>
                    new Response(response.body, {
                        headers: {
                            [HEADER_X_FILENAME]: `${fileName}.pdf`,
                        },
                    })
            )
    );
}

/**
 * Returns generated CSV invoice
 * @param {string} transactionsType
 * @param {string} id
 * @param {Object} range
 * @param {number} evseId
 * @param {string} language
 * @returns {Promise}
 */
async function fetchTransactionsCsv(
    transactionsType,
    { id, range, evseId, language }
) {
    const { startDate, endDate } = formatValuesInUtc(range);
    // The transactions report API takes an `evseId` using the name `connectorId`
    const params = {
        connectorId: evseId,
        startDate,
        endDate,
        browser_locale: language,
    };

    // remove undefined params
    Object.keys(params).forEach(
        (key) => params[key] === undefined && delete params[key]
    );

    return apiClient.goFetch(
        `/api/transactions/${transactionsType}/${id}/report`,
        {
            params,
            headers: {
                accept: 'text/csv',
            },
        }
    );
}

/**
 * Returns default transactions range - 1 month in the past from today's date inclusive
 * @returns {Object}
 */
export function getDefaultTransactionsRange() {
    const endDate = endOfToday();
    const startDate = startOfMonth(subMonths(endDate, 1));

    return { startDate, endDate };
}

/**
 * Transforms transaction details object
 * @param {Object} transaction
 * @returns {Object}
 */
export function transformTransactionDetails(transaction) {
    const { startedAt, endedAt, duration } = transaction;

    return {
        ...transaction,
        startedAtFormatted: new Date(startedAt).toLocaleString(),
        endedAtFormatted: new Date(endedAt).toLocaleString(),
        duration: dayjs.duration(duration).asMilliseconds(),
        period: {
            start: startedAt,
            end: endedAt,
            humanisedDuration: humanizeDuration(duration),
        },
        showDetails: false,
        loaded: false,
    };
}

/**
 * Get transaction Eichrecht information by transaction id
 * @param {string} id
 * @returns {Promise.<string>}
 */
export function getEichrechtData(id) {
    const tenantId = getters?.tenantId;

    return apiClient
        .goFetch(
            `/api/transactions/eichrecht/tenant/${tenantId}/transaction/${id}`
        )
        .then((response) => response?.text());
}

/**
 * Returns generated PDF or CSV transactions report localized to the provided locale
 * @param {string} fileType
 * @param {Object} params
 * @param {string} transactionsType
 * @returns {Promise}
 */
export async function downloadTransactions(fileType, params, transactionsType) {
    const language =
        localStorage.getItem('NG_TRANSLATE_LANG_KEY') ||
        processBrowserLocale(navigator.language);

    params.language = language;

    const downloadPromise = fileType.includes('pdf')
        ? await fetchTransactionsPdf(transactionsType, params)
        : await fetchTransactionsCsv(transactionsType, params);

    return downloadResponseAsFile(downloadPromise);
}

export default {
    getDefaultTransactionsRange,
    transformTransactionDetails,
    getEichrechtData,
    downloadTransactions,
};
