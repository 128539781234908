import dayjs from '../../services/wrappers/dayjs';

/**
 * Subtract the specified number of months from the given date
 * @param {Date} date
 * @param {number} amount
 * @returns {Date}
 */
export default function subMonths(date, amount) {
    return dayjs(date).subtract(amount, 'month').toDate();
}
