import { MATOMO_CONTAINER_IDS } from './core/constants';

// TODO: investigate the option of using the container environments instead of two containers.
// It depends on the decision about how to use the sites/containers created. It does look like the right way to do it
// when using the tag manager.

// Matomo Tag Manager
let containerId = MATOMO_CONTAINER_IDS.SANDBOX;

if (import.meta.env.PROD) {
    containerId = MATOMO_CONTAINER_IDS.PRODUCTION;
}

const _mtm = (window._mtm = window._mtm || []);

_mtm.push({ 'mtm.startTime': new Date().getTime(), event: 'mtm.Start' });
(function () {
    const d = document,
        g = d.createElement('script'),
        s = d.getElementsByTagName('script')[0];

    g.async = true;
    g.src = `https://cdn.matomo.cloud/evbox.matomo.cloud/container_${containerId}.js`;
    s.parentNode.insertBefore(g, s);
})();
