import { getters as authGetters } from '../services/auth.service.js';
import {
    isEmployeeOnboarding,
    isOnboardingOnBehalf,
    isSelfSignupOnboarding,
} from './useOnboardingCheck.js';
import {
    ensureFeatureFlags,
    getters as settings,
} from '../services/tenant-settings/tenant-settings.service.js';
import { getters as subscriptionGetters } from '../services/subscription/subscription.service.js';
import { SETUP_FLOWS } from '../core/constants.js';
import { getBillingInfoStatus } from '../services/account/account.service.js';

// WARNING: Do not add VUE logic into this composable, since it's being used by other files as well
// TODO: move this composable into a service https://myevbox.atlassian.net/browse/CN-10453

// eslint-disable-next-line complexity
export const accountSetupCompleteHook = async () => {
    if (!authGetters.isAuthenticated) {
        return {
            isSetupComplete: false,
        };
    }

    if (authGetters.isTenantLevelUser) {
        return {
            isSetupComplete: true,
        };
    }

    const [accountSetupComplete] = await Promise.all([
        getBillingInfoStatus(),
        // Wait for feature flags so that `settings.isTenantAccountBased` gives the right result
        ensureFeatureFlags(),
    ]);

    if (settings.isTenantAccountBased) {
        const [
            isNewSelfSignupOnboarding,
            isNewOnboardingOnBehalf,
            isNewEmployeeOnboarding,
        ] = await Promise.all([
            isSelfSignupOnboarding(),
            isOnboardingOnBehalf(),
            isEmployeeOnboarding(),
        ]);

        if (
            isNewSelfSignupOnboarding ||
            isNewOnboardingOnBehalf ||
            isNewEmployeeOnboarding
        ) {
            const setupFlow = decideSetupFlow(
                isNewOnboardingOnBehalf,
                isNewEmployeeOnboarding
            );

            return {
                isSetupComplete: false,
                setupFlow,
            };
        }

        if (
            !accountSetupComplete &&
            !subscriptionGetters.isAssetBasedSubscription
        ) {
            return {
                isSetupComplete: false,
            };
        }
    }

    return {
        isSetupComplete: accountSetupComplete,
    };
};

function decideSetupFlow(isNewOnboardingOnBehalf, isNewEmployeeOnboarding) {
    let setupFlow = SETUP_FLOWS.SELF;

    if (isNewOnboardingOnBehalf) {
        setupFlow = SETUP_FLOWS.ON_BEHALF;
    }

    if (isNewEmployeeOnboarding) {
        setupFlow = SETUP_FLOWS.EMPLOYEE;
    }

    return setupFlow;
}

export default {
    accountSetupCompleteHook,
};
