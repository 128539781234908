import connectorActions from './connector.actions';
import dashboardActions from './dashboard.actions';
import stationsActions from './station.actions';
import urlActions from './url.actions';

export default {
    ...stationsActions,
    ...dashboardActions,
    ...urlActions,
    ...connectorActions,
};
