import getDefaultValidationMessages from './default-validation-messages';
import { objects } from '../utils';

/**
 * Extends field with dynamic and generic options
 * @param {Object} field
 * @param {Object} cssClasses
 * @param {Object} options
 * @param {Object} [options.validationMessages]
 * @param {Object} [options.overrides]
 * @param {Object} [options.cssClasses]
 * @returns {Object}
 */
function extendField(
    field,
    {
        validationMessages: optionsValidationMessages,
        overrides = {},
        cssClasses = {},
    }
) {
    const { name, cssClass = '', validationMessages = {}, ...rest } = field;
    const componentCustomCss = `${cssClass} ${
        cssClasses[name] || cssClasses.default || ''
    }`.trim();

    if (overrides[name] instanceof Object && !Array.isArray(overrides[name])) {
        overrides[name] = objects.deepMerge(field, overrides[name]);
    }

    return {
        ...rest,
        name,
        componentCustomCss,
        validationMessages: {
            ...optionsValidationMessages,
            ...validationMessages,
        },
        ...overrides[name],
    };
}

/**
 * Filters fields by condition in `includeIf`.
 * Extends filtered fields.
 * @param {Array} fields
 * @param {Object} [options]
 * @returns {Array}
 */
export default function (fields, options = {}) {
    const { filterCondition, validationMessages = {}, ...rest } = options;

    const messages = {
        ...getDefaultValidationMessages(),
        ...validationMessages,
    };

    return fields.reduce((reduced, field) => {
        const { includeIf, ...fieldConfig } = field;

        if (!includeIf || includeIf?.(filterCondition)) {
            reduced.push(
                extendField(fieldConfig, {
                    validationMessages: messages,
                    ...rest,
                })
            );
        }

        return reduced;
    }, []);
}
