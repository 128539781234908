export const STRING_TYPE = '[object String]';
export const NUMBER_TYPE = '[object Number]';
export const BOOLEAN_TYPE = '[object Boolean]';
export const ARRAY_TYPE = '[object Array]';
export const FUNCTION_TYPE = '[object Function]';
export const OBJECT_TYPE = '[object Object]';
export const NULL_TYPE = '[object Null]';
export const UNDEFINED_TYPE = '[object Undefined]';
export const DATE_TYPE = '[object Date]';
export const REGEXP_TYPE = '[object RegExp]';
export const SYMBOL_TYPE = '[object Symbol]';
export const ANY_TYPE = '___AnyType___';

export function getTypeOf(object) {
    return Object.prototype.toString.call(object);
}

export function isInstanceOf(object, ...testTypes) {
    const objectType = getTypeOf(object);

    return testTypes.some(
        (testType) => testType === objectType || testType === ANY_TYPE
    );
}

export function deepMerge(defaultObj, customObj) {
    const mergedObj = { ...defaultObj };

    for (const prop in customObj) {
        if (
            Object.prototype.hasOwnProperty.call(customObj, prop) &&
            isInstanceOf(mergedObj[prop], OBJECT_TYPE)
        ) {
            mergedObj[prop] = deepMerge(mergedObj[prop], customObj[prop]);
        } else {
            mergedObj[prop] = customObj[prop];
        }
    }

    return mergedObj;
}

export function sortByKey(object) {
    if (!isInstanceOf(object, OBJECT_TYPE)) {
        return;
    }

    return Object.fromEntries(Object.entries(object).sort());
}

export function isEmpty(object) {
    return isInstanceOf(object, OBJECT_TYPE) && !Object.keys(object).length;
}

/**
 * Returns a deep clone of the given object, with some caveats.
 *
 * This function does not create an exact clone of Dates, functions, undefined, Infinity, RegExps, Maps, Sets, Blobs, FileLists, ImageDatas, sparse Arrays, Typed Arrays or other complex types.
 * For more information: https://stackoverflow.com/questions/122102/what-is-the-most-efficient-way-to-deep-clone-an-object-in-javascript#answer-122704.
 * TODO: When we adopt Node 17 or higher, we can use `structuredClone` instead.
 *
 * @param {Object} object
 * @returns {Object}
 */
export function unsafeDeepClone(object) {
    return JSON.parse(JSON.stringify(object));
}

/**
 * Gets object deep value based on a path
 * @param {Object} obj - example would be {key: {childKey: value}}
 * @param {string} path - example would be 'key.childKey
 * @returns {Object}
 */
export function getDeepValue(obj, path) {
    const array = path.split('.');

    for (let i = 0; i < array.length; i += 1) {
        obj = obj[array[i]];
    }

    return obj;
}

/**
 * Sets object deep value based on a path
 * @param {Object} obj - example would be {key: {childKey: value}}
 * @param {string} path - example would be 'key.childKey
 * @param {any} value - it can be anything that is assigned to childKey
 */
export function setDeepValue(obj, path, value) {
    const array = path.split('.');
    let objectClone = obj;

    while (array.length - 1) {
        const key = array.shift();

        if (!(key in objectClone)) {
            objectClone[key] = {};
        }

        objectClone = objectClone[key];
    }

    objectClone[array[0]] = value;
}

/**
 * Verifies that two objects with maximum depth one are equal
 * @param {Object} objectA
 * @param {Object} objectB
 * @returns {boolean}
 */
export function shallowEqual(objectA, objectB) {
    if (
        !isInstanceOf(objectA, OBJECT_TYPE) ||
        !isInstanceOf(objectB, OBJECT_TYPE)
    ) {
        return false;
    }

    const objectAKeys = Object.keys(objectA);

    return (
        objectAKeys.length === Object.keys(objectB).length &&
        objectAKeys.every((key) => objectA[key] === objectB[key])
    );
}

/**
 * Filters out properties with value equal to any of the undesired values from the object.
 * It accepts single undesired value as well as an array of them.
 * If `object` param is not an Object, it is directly returned.
 * @param {Object} object
 * @param {*|Array} undesiredValues
 * @returns {Object}
 */
export function omitByValue(object, undesiredValues = []) {
    if (!isInstanceOf(object, OBJECT_TYPE)) {
        return object;
    }

    undesiredValues = Array.isArray(undesiredValues)
        ? undesiredValues
        : [undesiredValues];

    return Object.keys(object).reduce(
        (acc, key) => {
            undesiredValues.includes(acc[key]) && delete acc[key];

            return acc;
        },
        { ...object }
    );
}

/**
 * Returns object cleared from given params.
 * Accepts either an array of param to omit or a name of a single param.
 * @param {Object} object
 * @param {Array|string} undesiredProps
 * @returns {Object}
 */
export function omit(object, undesiredProps = []) {
    undesiredProps =
        getTypeOf(undesiredProps) === STRING_TYPE
            ? [undesiredProps]
            : undesiredProps;

    if (!Array.isArray(undesiredProps) || !undesiredProps.length) {
        return object;
    }

    return Object.entries(object).reduce((acc, [key, value]) => {
        if (!undesiredProps.includes(key)) {
            acc[key] = value;
        }

        return acc;
    }, {});
}

/**
 * Returns the object with only the chosen properties.
 * @param {Object} object
 * @param {Array} propsToPick
 * @returns {Object}
 */
export function pick(object, propsToPick = []) {
    if (!Array.isArray(propsToPick) || !propsToPick.length) {
        return object;
    }

    return Object.entries(object).reduce((acc, [key, value]) => {
        if (propsToPick.includes(key)) {
            acc[key] = value;
        }

        return acc;
    }, {});
}
