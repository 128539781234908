import { CONNECTOR_STATUS, CPO_DASHBOARD } from '../../../../core/constants';
import { toScreamingSnakeCase } from '../../../../utils/strings';
import { countConnectors } from '../../../../services/api/stations/v1.api';

function buildConnectorsStatusDataset(connectorStatusData) {
    return CPO_DASHBOARD.CONNECTOR_STATUSES.map((status) => ({
        name: status,
        label: CONNECTOR_STATUS.LABELS[status],
        value: connectorStatusData[toScreamingSnakeCase(status)] || 0,
        class: status,
        color: CONNECTOR_STATUS.COLORS[status],
    }));
}

function buildConnectorTypesDataset(connectorsStandardData) {
    if (connectorsStandardData instanceof Object) {
        const entries = Object.entries(connectorsStandardData);

        return entries.map(([label, value]) => ({ label, value }));
    }

    return [];
}

export default {
    fetchConnectorsData({ commit, getters }) {
        commit('update', [
            'connectorsData',
            {
                statuses: [],
                types: [],
                isConnectorStatusesLoading: true,
                isConnectorTypesLoading: true,
            },
        ]);

        const query = { lifecycleState: 'ACTIVE' };

        if (getters.selectedAccountId) {
            query.accountId = getters.selectedAccountId;
        }

        countConnectors({
            ...query,
            groupBy: 'AVAILABILITY_STATUS',
            connectivityStatus: 'ONLINE',
        })
            .then((connectorsStatus) => {
                commit('setConnectorsData', {
                    statuses: buildConnectorsStatusDataset(connectorsStatus),
                    isConnectorStatusesLoading: false,
                });
            })
            .catch((error) => {
                if (!error.status) {
                    throw error;
                }

                commit('setConnectorsData', {
                    isConnectorStatusesLoading: false,
                });
            });

        countConnectors({ ...query, groupBy: 'CONNECTOR_TYPE' })
            .then((connectorsTypes) => {
                commit('setConnectorsData', {
                    types: buildConnectorTypesDataset(connectorsTypes),
                    isConnectorTypesLoading: false,
                });
            })
            .catch(() => {
                commit('setConnectorsData', {
                    isConnectorTypesLoading: false,
                });
            });
    },
};
