import apiClient from '../../../core/api/api-client';

/**
 * Checks if Advenir is enabled on a station
 * @param {string} stationId
 * @returns {Promise}
 */
export async function isAdvenirEnabled(stationId) {
    const response = await apiClient.head(
        `/api/billing/advenir/stations/${stationId}/enabled`
    );

    if (response.status === 200) {
        return true;
    }

    if (response.status === 204) {
        return false;
    }

    throw new Error(
        `To determine whether Advenir is enabled on station ${stationId}, the response status must be 200 or 204, but it was ${response.status}`
    );
}

/**
 * Enables Advenir on a station
 *
 * This call will fail if Advenir is not enabled on the account, or if the station's location is not in France
 *
 * @param {string} stationId
 * @returns {Promise}
 */
export function enableAdvenir(stationId) {
    return apiClient.put(`/api/billing/advenir/stations/${stationId}/enable`);
}

/**
 * Disables Advenir on a station
 * @param {string} stationId
 * @returns {Promise}
 */
export function disableAdvenir(stationId) {
    return apiClient.put(`/api/billing/advenir/stations/${stationId}/disable`);
}

export default { isAdvenirEnabled, enableAdvenir, disableAdvenir };
