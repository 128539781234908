import getters from './getters';
import createInitialState from './initialState';
import mutations from './mutations';
import actions from './actions';

export default {
    state: () => createInitialState(),
    mutations,
    getters,
    actions,
};
