import { countStations } from '../../../../services/api/stations/v1.api';
import { state as authState } from '../../../../services/auth.service';

export default {
    setupCurrentUserProfile({ commit }) {
        commit('setCurrentUserProfile', authState.profile);
    },

    setupDashboardActions({ dispatch, state }) {
        dispatch('changeView', {
            params: { view: state.queryParams.view },
        });
        dispatch('fetchSummaryData');
    },

    changeView({ commit }, { params = {} } = {}) {
        commit('update', ['selectedView', params.view]);
    },

    async fetchSummaryData({ commit, getters }) {
        const { selectedAccountId } = getters;

        commit('setLoadingStateForTiles');

        const query = selectedAccountId ? { accountId: selectedAccountId } : {};

        const countOnlineStationsPromise = countStations({
            ...query,
            connectivityStatus: 'ONLINE',
            lifecycleState: 'ACTIVE',
        });
        const countOfflineStationsPromise = countStations({
            ...query,
            connectivityStatus: 'OFFLINE',
            lifecycleState: 'ACTIVE',
        });
        const countInactiveStationsPromise = countStations({
            ...query,
            lifecycleState: 'INACTIVE',
        });

        try {
            const { TOTAL: ONLINE } = await countOnlineStationsPromise;
            const { TOTAL: OFFLINE } = await countOfflineStationsPromise;
            const { TOTAL: INACTIVE } = await countInactiveStationsPromise;

            commit('updateTiles', {
                online: ONLINE ?? 0,
                offline: OFFLINE ?? 0,
                inactive: INACTIVE ?? 0,
            });
        } catch (e) {
            console.error(
                'Something went wrong getting station count by lifecycle state: ',
                e
            );
        }
    },
};
