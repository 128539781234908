import { state } from '../services/auth.service';

/**
 * Looks for a singe permission in the permissions list
 * @param {string} permission the permission
 * @returns {boolean} true if the permission in included
 */
export const hasPermission = (permission) => {
    const permissions = state.profile?.permissions ?? [];

    return permissions.includes(permission);
};

/**
 * Looks for a list of permissions in the permissions list
 * @param {Array<string>} requiredPermissions the permissions list
 * @returns {boolean} true if all the permissions are included
 */
export const hasAllPermissions = (requiredPermissions = []) => {
    const permissions = state.profile?.permissions ?? [];

    return requiredPermissions.every((permission) =>
        permissions.includes(permission)
    );
};

/**
 * Looks for a list of permissions in the permissions list
 * @param {Array<string>} userPermissions the permissions list
 * @returns {boolean} true if any permission is included
 */
export const hasAnyPermission = (userPermissions) => {
    const permissions = state.profile?.permissions ?? [];

    return userPermissions.some((permission) =>
        permissions.includes(permission)
    );
};
