import { findTransactionForEvse } from '../utils';
import { chargeWithMaxPower } from '../../../../services/api/stations/v1/charging-profiles.api';
import {
    handleSuccess,
    handleUserError,
} from '../../../../utils/handle-generic-notifications';

const NOTIFICATION_DELAY = 5_000;

export default function getOverrideMaxPowerActions() {
    return {
        confirmOverrideMaxPower({ commit }, { connector }) {
            commit('confirmOperation', { action: 'override', connector });
        },

        async overrideMaxPower({ commit, dispatch, state }) {
            commit('startCurrentOperation');

            const params = {
                stationId: state.stationId,
                evseCode: state.currentConnector.evseCode,
                transactionId: findTransactionForEvse(
                    state.openTransactions,
                    state.currentConnector
                ).id,
            };

            try {
                await chargeWithMaxPower(params);

                handleSuccess(
                    'stations.components.connectorsControl.overrideMaxPowerAccepted',
                    {
                        connectorIdentityCode: state.currentConnector.code,
                        delay: NOTIFICATION_DELAY,
                    }
                );
            } catch (error) {
                if (!error.status) {
                    throw error;
                }

                handleUserError(
                    'stations.components.connectorsControl.overrideMaxPowerFailed',
                    {
                        connectorIdentityCode: state.currentConnector.code,
                        delay: NOTIFICATION_DELAY,
                    }
                );
            } finally {
                commit('finishCurrentOperation');
                await dispatch('getStation');
            }
        },
    };
}
