// this is the plce where we assemble modules and export the store
import { createStore } from 'vuex';
import cpoDashboard from './modules/cpo-dashboard';
import connectorsControl from './modules/connectors-control';
import stationLocation from './modules/station-location';

export function createEveronStore() {
    return createStore({
        modules: {
            cpoDashboard,
            connectorsControl,
            stationLocation,
        },
        strict: process.env.NODE_ENV !== 'production',
    });
}
