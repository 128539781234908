/**
 * Checks if value is included into the conditions
 * @param {Array|string} conditions
 * @returns {Function}
 */
export function includes(conditions = []) {
    return (condition) => conditions?.includes?.(condition) || false;
}

/**
 * Checks if value is not included into the conditions
 * @param {Array|string} conditions
 * @returns {Function}
 */
export function excludes(conditions = []) {
    return (condition) => !includes(conditions)(condition);
}
