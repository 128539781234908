import { createApp } from 'vue';
import * as components from '@everon/jolt/components';
import { iconsLoader } from '@everon/jolt/loaders';
import '@everon/jolt/style.css';
import { createEveronRouter, setupEveronRouter } from './core/router';
import './style.css';
import App from './app.vue';
import loadModules from './core/moduleLoader';

import { createEveronStore } from './store';
import i18next from 'i18next';
import HttpApi from 'i18next-http-backend';
import I18NextVue from 'i18next-vue';
import { getLanguageFromLocalStorage } from './services/language/language.service';

async function bootstrap() {
    // register all Jolt components and directives
    // vite isn't able to use the Jolt native register

    function registerJoltComponents(components) {
        // eslint-disable-next-line guard-for-in
        for (const component in components) {
            app.component(`jolt${component}`, components[component]);
            app.component(`j${component}`, components[component]);
        }
    }

    const app = createApp(App);

    iconsLoader(window);

    registerJoltComponents(components);

    const assetPath = EVERON_CDN_URL ? `https://${EVERON_CDN_URL}` : '';

    // eslint-disable-next-line import/no-named-as-default-member
    await i18next.use(HttpApi).init({
        lng: getLanguageFromLocalStorage(), // if you're using a language detector, do not define the lng option
        load: 'currentOnly',
        fallbackLng: 'en-GB',
        backend: {
            loadPath: `${assetPath}/translations/{{lng}}.json`,
            crossDomain: true,
        },
    });

    app.use(I18NextVue, { i18next });

    const router = createEveronRouter();

    setupEveronRouter(router);
    await loadModules(router);

    app.use(router);
    app.use(createEveronStore());
    app.mount('#app');
}

// do not start the mock service worker in production
if (EVERON_SKIP_AUTH === 'true' && import.meta.env.MODE !== 'production') {
    const { worker } = await import('../tests/component-ui/mocks/browser');

    worker.start();
}

bootstrap();
