/**
 * Use to cancel asynchronous processes
 */
export default class CancelToken {
    constructor() {
        this._callbacks = [];
        this.isCancelled = false;
        this.cancelArgs = null;
    }

    /**
     * Registers a function to be called upon cancellation
     * @param {Function} callback
     */
    onCancel(callback) {
        this._callbacks.push(callback);
    }

    /**
     * Signal cancellation
     * @param  {...any} args Arguments to be passed to the cancellation callbacks
     */
    cancel(...args) {
        this.isCancelled = true;
        this.cancelArgs = args;
        this._callbacks.forEach((callback) => callback(...args));
    }
}
