import CancelToken from './CancelToken';
import forever from './forever';
import sleep from './sleep';

/**
 * Represents timeouts
 */
export class TimeoutError extends Error {
    constructor(message) {
        super(message);
        this.name = 'TimeoutError';
        this.status = -1;
    }
}

/**
 * Wait for the given duration
 * @param {number} [duration] How long to wait. If given a number, waits for that many milliseconds. If omitted, waits forever.
 * @param {Object} cancelToken May be used to stop wait from resolving
 * @returns {Promise}
 */
function wait(duration, cancelToken) {
    if (typeof duration === 'number') {
        return sleep(duration, null, cancelToken);
    }

    return forever();
}

/**
 * Poll the given function
 *
 * Usage example:
 *
 * ```javascript
 * try {
 *     store.posts = await poll(apiClient.fetchPosts, {
 *         until: myPostPublished,
 *         interval: 3 * 1000, // Every three seconds
 *         timeout: 60 * 1000, // Max one minute
 *     });
 * } catch (error) {
 *     if (error instanceof TimeoutError) showTimeoutMessage();
 *     ...
 * }
 * ```
 *
 * The `until` predicate receives the returned or resolved value from the polled function. Polling is stopped as soon as it returns `true`:
 *
 * ```
 * function myPostPublished(posts) {
 *     return posts.some(post => post.id === myPostId);
 * }
 * ```
 *
 * ## Stop polling manually
 *
 * Use a `CancelToken` to stop polling whenever you like:
 *
 * ```javascript
 * const cancelToken = new CancelToken();
 *
 * myCancelButton.onClick = () => cancelToken.cancel();
 *
 * await poll(fetchPosts, {
 *     cancelToken,
 *     ...
 * ```
 *
 * ## Dynamic interval
 *
 * You can pass an `interval` function to make it dynamic. It gets passed an `i` for the number of intervals passed thus far:
 *
 * ```javascript
 * // Exponential intervals: Will return 100, 200, 400, 800, 1600, etc.
 * const doublingFrom100 = i => 100 * 2 ** i;
 *
 * // Will send a request at 0ms, 100ms, 300ms, 700ms, 1500ms, etc.
 * await poll(fetchPosts, {
 *     interval: doublingFrom100,
 *     ...
 * ```
 *
 * ## Error handling
 *
 * If you omit the `until` predicate because there's no condition for which to stop polling, don't forget to catch errors on the "polling promise": It will fail on when the timeout is reached!
 *
 * If you do not want errors thrown in the polled `fn` to propagate up, you should catch them in the `fn` itself, for example using the "Result" pattern. Don't forget to make the `until` predicate compatible!
 *
 * ```javascript
 * async function fetchPosts() {
 *     try {
 *         return {value: await apiClient.fetchPosts()};
 *     } catch (error) {
 *         return {error};
 *     }
 * }
 *
 * function myPostPublished(postsResult) {
 *     return postsResult.value?.some(post => post.id === myPostId);
 * }
 *
 * await poll(fetchPosts, {
 *     until: myPostPublished,
 *     ....
 * ```
 *
 * @param {Function} fn The function to poll
 * @param {Function|number} [options.until] Make polling stop. If given a function, which will be passed
 *  the last result of calling `fn`, stops when this predicate return `true`. If given a number, stops after that many milliseconds passed. If omitted, will not cause polling to stop.
 * @param {number|Function} options.interval Time between two calls to the given function, in milliseconds or a function that returns milliseconds
 * @param {number} [options.timeout] Make polling stop, causing `poll` to throw `TimeoutError`. If given a number, stops after that many milliseconds passed. If omitted, will not cause polling to stop.
 * @param {CancelToken} options.cancelToken Token with which polling can be stopped manually
 * @returns {Promise}
 */
export default function poll(fn, { until, interval, timeout, cancelToken }) {
    if (cancelToken?.isCancelled) {
        return forever();
    }

    if (until == null && timeout == null) {
        console.warn(
            `Polling function ${fn.name} without either of the arguments "until" or "timeout" will cause it to never stop polling. If this is your intent, supress this warning by supplying "until" with a function that always returns false`
        );
    }

    const startTime = Date.now();
    let nextRequestTime = startTime;

    let i = 0;
    let isCancelled = false;

    const cancelNextRequestToken = new CancelToken();
    const cancelTimeoutToken = new CancelToken();
    const cancelUntilToken = new CancelToken();

    function cancel() {
        isCancelled = true;
        cancelNextRequestToken.cancel();
        cancelTimeoutToken.cancel();
        cancelUntilToken.cancel();
    }

    cancelToken?.onCancel(cancel);

    const waitingUntil = wait(until, cancelUntilToken).then(cancel);
    const timingOut = wait(timeout, cancelTimeoutToken).then(() => {
        cancel();
        throw new TimeoutError(
            `Polling function "${fn.name}" exceeded timeout ${timeout}`
        );
    });

    function updateNextRequestTime() {
        const nextInterval = (() => {
            if (typeof interval === 'number') {
                return interval;
            }

            if (typeof interval === 'function') {
                const next = interval(i);

                if (typeof next === 'number') {
                    return next;
                }

                throw new Error(
                    `interval should be a number or a function that returns a number, received a function that returns ${next} of type ${typeof next} instead`
                );
            }

            throw new Error(
                `interval should be a number or a function that returns a number, received ${interval} of type ${typeof interval} instead`
            );
        })();

        i += 1;
        nextRequestTime += nextInterval;
    }

    function getTimeTillNextRequest() {
        return nextRequestTime - Date.now();
    }

    async function pollRecursive() {
        const result = await fn();

        updateNextRequestTime();

        if (typeof until === 'function' && until(result)) {
            return result;
        }

        await sleep(getTimeTillNextRequest(), null, cancelNextRequestToken);

        if (isCancelled) {
            return;
        }

        return pollRecursive();
    }

    const polling = pollRecursive();

    polling.then(cancel);

    return Promise.race([polling, timingOut, waitingUntil]);
}
