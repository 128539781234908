import { ERROR_CODES } from '../../../core/constants';
import { shallowEqual } from '../../../utils/objects';
import { stationFormNeededFields } from '../../../forms/configs/address';
import {
    getStation,
    updateStationLocation,
} from '../../../services/api/stations/v1.api';
import { updateSiteAssociation } from '../../../services/api/stations/v1/sites.api';
import {
    disableAdvenir,
    enableAdvenir,
} from '../../../services/api/billing/advenir.api';
import {
    useCountryCode,
    useCountryObject,
} from '../../../forms/address-helpers';
import {
    handleSuccess,
    handleUserError,
} from '../../../utils/handle-generic-notifications';

const addressEmptyObject = {
    city: null,
    country: null,
    house: null,
    postcode: null,
    state: null,
    street: null,
};

/**
 * Returns only the address fields actually controlled by the address form.
 * Without this, we might send `administrativeArea` of the previous address, which results in a validation failure.
 * @param {Object} address
 * @returns {Object}
 */
function useFormValues(address) {
    const allEntries = Object.entries(address);
    const formEntries = allEntries.filter(([key]) =>
        stationFormNeededFields.includes(key)
    );

    return Object.fromEntries(formEntries);
}

/**
 * Generates update station request's payload.
 * If the station does belong to a site, the address object is not sent in the request's payload.
 * @param {Object} state
 * @param {Object} state.addressModel
 * @param {Object} state.geocoordinatesModel
 * @param {string} state.directionModel
 * @param {Array} state.countries
 * @param {Object} state.site
 * @returns {Object}
 */
function buildUpdateStationPayload({
    addressModel,
    geocoordinatesModel,
    directionModel,
    countries,
    site,
}) {
    const payload = {
        geocoordinates: geocoordinatesModel,
        direction: directionModel,
    };

    if (site) {
        return payload;
    }

    const formAddress = useFormValues(addressModel);

    payload.address = useCountryObject(formAddress, countries);

    return payload;
}

function getUpdateStationNotification(stationUpdated, advenirUpdated) {
    if (stationUpdated.status === 'rejected') {
        const messageKey =
            stationUpdated.reason.status === 417
                ? 'generic.error.invalidAddress'
                : ERROR_CODES[stationUpdated.reason.status];

        return { type: 'error', messageKey };
    } else if (advenirUpdated.status === 'rejected') {
        return {
            type: 'error',
            messageKey: ERROR_CODES[advenirUpdated.reason.status],
        };
    } else {
        return { type: 'success' };
    }
}

/**
 * Returns station related properties to set the store. If no station id (activation flow) returns
 * empty object to keep state's default values
 * @param {Object} station
 * @param {boolean} isAdvenirEnabledOnStation
 * @returns {Object}
 */
function getStationProperties(station, isAdvenirEnabledOnStation) {
    const stationProperties = { isAdvenirEnabledOnStation };

    if (station.id) {
        const { id, site, lifecycleState } = station;

        Object.assign(stationProperties, {
            stationId: id,
            site,
            belongsToSite: Boolean(site),
            knowsAddress: !site,
            active: lifecycleState === 'ACTIVE',
        });
    }

    return stationProperties;
}

/**
 * Gets actions to initialize the store.
 * Receives an object with needed API methods.
 * @param {Object} api
 * @param {Function} api.getStation
 * @param {Function} api.updateSiteAssociation
 * @returns {Object}
 */
export default function getActions() {
    /**
     * Sets whether Advenir is enabled on the station or not.
     * @param {string} stationId
     * @param {boolean} shouldAdvenirBeEnabled
     * @returns {Promise}
     */
    function setAdvenir(stationId, shouldAdvenirBeEnabled) {
        if (shouldAdvenirBeEnabled) {
            return enableAdvenir(stationId);
        } else {
            return disableAdvenir(stationId);
        }
    }

    /**
     * Updates station's address and geocoordinates in the store.
     * @param {Object} stationLocationUpdated
     * @param {Object} state
     * @param {Function} commit
     */
    function handleUpdateStationSuccess(stationLocationUpdated, state, commit) {
        const { location } = stationLocationUpdated.value ?? {};
        const { address, geocoordinates, direction } = location;

        const newAddress = useCountryCode(address);

        if (!shallowEqual(newAddress, state.address)) {
            commit('updateAddress', newAddress);
        }

        if (!shallowEqual(geocoordinates, state.geocoordinates)) {
            commit('updateGeocoordinates', geocoordinates);
        }

        commit('updateWithObject', { direction });
    }

    return {
        /**
         * Setups vuex store with initial data from BE
         * @param {Object} context
         * @param {Function} context.commit
         * @param {Object} resolvedData
         * @param {Object} resolvedData.station
         * @param {Object} resolvedData.isAdvenirEnabledOnStation
         * @param {Array} resolvedData.availableSites
         * @param {Object} resolvedData.accountFeatures
         */
        setupStore(
            { commit },
            {
                station = {},
                isAdvenirEnabledOnStation,
                availableSites = [],
                accountFeatures,
                ...rest
            }
        ) {
            const { address, geocoordinates, direction } =
                station.location ?? {};

            commit('updateWithObject', {
                ...rest,
                availableSites,
                isAdvenirEnabledOnAccount: accountFeatures?.advenir,
                direction: direction || '',
                directionModel: direction || '',
                ...getStationProperties(station, isAdvenirEnabledOnStation),
            });
            commit('updateAddress', useCountryCode(address ?? {}));
            commit('updateGeocoordinates', geocoordinates);
        },

        /**
         * Updates state property `belongsToSite` to the value of the `belongsToSite` parameter.
         * If the station is assigned to a site, the relationship is broken.
         * @param {Object} context
         * @param {Function} context.commit
         * @param {Function} context.dispatch
         * @param {Object} context.state
         * @param {boolean} belongsToSite
         */
        toggleSiteBelonging({ commit, dispatch, state }, belongsToSite) {
            // for some reason, the value of `belongsToSite` is sometimes an event object
            // and sometimes a boolean, we only want to handle the boolean case
            // it seems that multiple jolt components are emitting the event, but only one of them is emitting the boolean
            // TODO: figure out why this is happening
            if (typeof belongsToSite !== 'boolean') {
                return;
            }

            commit('updateWithObject', { belongsToSite });

            if (state.site) {
                dispatch('toggleSiteAssignment', null);
                dispatch('toggleKnownAddress', true);
            } else {
                commit('updateWithObject', {
                    siteSearchQuery: '',
                    selectedSiteId: null,
                });
            }
        },

        /**
         * Updates state property `knowsAddress` to the value of the `knowsAddress` parameter.
         * @param {Object} context
         * @param {Function} context.commit
         * @param {Function} context.dispatch
         * @param {boolean} knowsAddress
         */
        toggleKnownAddress({ commit, dispatch }, knowsAddress) {
            // check toggleSiteBelonging for explanation
            if (typeof knowsAddress !== 'boolean') {
                return;
            }

            if (knowsAddress) {
                dispatch('updatePlaceholder', null);
            }

            commit('updateWithObject', { knowsAddress });
        },

        /**
         * Updates station entity with normalised address (if needed), geocoordinates and optional extra props.
         * @param {Object} context
         * @param {Function} context.commit
         * @param {Object} context.state
         * @param {Object} context.getters
         * @returns {Promise}
         */
        async updateStationLocation({ commit, state, getters }) {
            commit('updateWithObject', { saveInProgress: true });

            // We don't need to call `updateStationLocation` if only advenir is being updated, but writing that checking logic
            // doesn't seem worth the effort, so we just call it always
            const requestPayload = buildUpdateStationPayload(state);
            const updatingStation = updateStationLocation(
                state.stationId,
                requestPayload
            );

            const shouldUpdateAdvenir =
                getters.isAdvenirAvailable &&
                state.isAdvenirEnabledOnStation !==
                    state.isAdvenirEnabledOnStationModel;
            const updatingAdvenir = shouldUpdateAdvenir
                ? setAdvenir(
                      state.stationId,
                      state.isAdvenirEnabledOnStationModel
                  )
                : null;

            const results = await Promise.allSettled([
                updatingStation,
                updatingAdvenir,
            ]);

            // Print non-HTTP errors
            results.forEach((result) => {
                if (result.status === 'rejected' && !result.reason.status) {
                    console.error(result.reason);
                }
            });

            const [stationUpdated, advenirUpdated] = results;

            // State changes for station update
            if (stationUpdated.status === 'fulfilled') {
                handleUpdateStationSuccess(stationUpdated, state, commit);
            }

            // State changes for advenir update
            if (shouldUpdateAdvenir && advenirUpdated.status === 'fulfilled') {
                commit('updateWithObject', {
                    isAdvenirEnabledOnStation:
                        state.isAdvenirEnabledOnStationModel,
                });
            }

            const notification = getUpdateStationNotification(
                stationUpdated,
                advenirUpdated
            );

            if (notification.type === 'success') {
                handleSuccess(notification.messageKey);
            }

            if (notification.type === 'error') {
                handleUserError(notification.messageKey);
            }

            commit('updateWithObject', { saveInProgress: false });
        },

        /**
         * Assigns station to a site.
         * Unassigns in case if siteId is null.
         * @param {Object} context
         * @param {Function} context.commit
         * @param {Object} context.state
         * @param {string|null} siteId
         */
        async toggleSiteAssignment({ commit, state }, siteId) {
            commit('updateWithObject', { toggleSiteInProgress: true });

            const action = siteId ? 'assign' : 'unassign';

            try {
                await updateSiteAssociation(state.stationId, siteId);

                handleSuccess(
                    `stations.station.location.form.notification.${action}.success`
                );
            } catch (error) {
                if (!error?.status) {
                    throw error;
                }

                handleUserError(
                    `stations.station.location.form.notification.${action}.failure`
                );

                commit('updateWithObject', { toggleSiteInProgress: false });

                return;
            }

            try {
                const station = await getStation(state.stationId);
                const address = station?.location.address;

                commit('updateAddress', useCountryCode(address));
                commit('updateWithObject', { site: station.site });
            } catch (error) {
                if (!error?.status) {
                    throw error;
                }

                // Not going through the hassle of implementing a special error message for this case, because if
                // "getStation" fails, the user probably can't see this page anyway.
            } finally {
                commit('updateWithObject', { toggleSiteInProgress: false });
            }
        },

        /**
         * Updates local query variable.
         * @param {Object} context
         * @param {Function} context.commit
         * @param {string} query
         */
        onSiteSearchInput({ commit }, query) {
            commit('updateWithObject', { siteSearchQuery: query });
        },

        /**
         * Sets selected site from site search component.
         * @param {Object} context
         * @param {Function} context.commit
         * @param {Object} context.state
         * @param {string} siteId
         */
        onSiteSelected({ commit, state }, siteId) {
            if (siteId) {
                setTimeout(() => {
                    const selectedSite = state.availableSites.find(
                        (site) => site.id === siteId
                    );

                    commit('updateWithObject', {
                        selectedSiteId: siteId,
                        siteSearchQuery: selectedSite.name,
                        placeholder: null,
                    });
                }, 0);
            }
        },

        /**
         * Clears selected site and site query.
         * @param {Object} context
         * @param {Function} context.commit
         */
        onSiteSearchClear({ commit }) {
            commit('updateWithObject', {
                selectedSiteId: null,
                siteSearchQuery: '',
            });
        },

        /**
         * Sets `resolvingInfo` state property to the passed value.
         * @param {Object} context
         * @param {Function} context.commit
         * @param {boolean} resolving
         */
        updateResolvingInfo({ commit }, resolving) {
            commit('updateWithObject', { resolvingInfo: resolving });
        },

        /**
         * Sets form validity.
         * @param {Object} context
         * @param {Function} context.commit
         * @param {boolean} formIsValid
         */
        updateFormValidity({ commit }, formIsValid) {
            commit('updateWithObject', { formIsValid });
        },

        /**
         * Sets `placeholder` state property to the passed value.
         * @param {Object} context
         * @param {Function} context.commit
         * @param {string} placeholder
         */
        updatePlaceholder({ commit }, placeholder) {
            commit('updateWithObject', { placeholder });
        },

        /**
         * Sets address and geocoordinates models to the provided `address` and `geocoordinates` respectively.
         * @param {Object} context
         * @param {Function} context.commit
         * @param {Object} payload
         * @param {Object} payload.address
         * @param {Object} payload.geocoordinates
         */
        setModels({ commit }, { address, geocoordinates }) {
            commit('updateAddress', address);
            commit('updateGeocoordinates', geocoordinates);
        },

        /**
         * Resets address and geocoordinates models to state's `address` and `geocoordinates` respectively.
         * @param {Object} context
         * @param {Function} context.dispatch
         * @param {Object} context.state
         * @param {Object} context.state.address
         * @param {Object} context.state.geocoordinates
         * @param {Object} context.state.isAdvenirEnabledOnStation
         */
        resetModels({
            dispatch,
            state: { address, geocoordinates, isAdvenirEnabledOnStation },
        }) {
            dispatch('setModels', { address, geocoordinates });
            dispatch(
                'updateIsAdvenirEnabledOnStationModel',
                isAdvenirEnabledOnStation
            );
        },

        /**
         * Updates address model.
         * @param {Object} context
         * @param {Function} context.commit
         * @param {Object} address
         */
        updateAddressModel({ commit }, address) {
            commit('updateAddressModel', address);
        },

        /**
         * Updates geocoordinates model.
         * @param {Object} context
         * @param {Function} context.commit
         * @param {Object} geocoordinates
         */
        updateGeocoordinatesModel({ commit }, geocoordinates) {
            commit('updateGeocoordinatesModel', geocoordinates);
        },

        /**
         * Updates geocoordinates model from coordinates.
         * If an address is passed, updates address model as well.
         * @param {Object} context
         * @param {Function} context.getters
         * @param {Function} context.dispatch
         * @param {Object} models
         * @param {Object} models.geocoordinates
         * @param {Object} models.address
         */
        updateModelsFromCoords(
            { getters, dispatch },
            { geocoordinates, address }
        ) {
            dispatch('updateGeocoordinatesModel', geocoordinates);

            if (address && getters.locationFromCoords) {
                dispatch('updateAddressModel', address);
            }
        },

        /**
         * Updates direction
         * @param {Object} context
         * @param {Function} context.commit
         * @param {string} direction
         */
        updateDirection({ commit }, direction) {
            commit('updateWithObject', { directionModel: direction });
        },

        /**
         * Updates isAdvenirEnabledOnStationModel
         * @param {Object} context
         * @param {Function} context.commit
         * @param {boolean} isAdvenirEnabledOnStationModel
         */
        updateIsAdvenirEnabledOnStationModel(
            { commit },
            isAdvenirEnabledOnStationModel
        ) {
            if (typeof isAdvenirEnabledOnStationModel !== 'boolean') {
                return;
            }

            commit('updateWithObject', { isAdvenirEnabledOnStationModel });
        },

        /**
         * Replaces address model data with placeholder.
         * @param {Object} context
         * @param {Function} context.dispatch
         * @param {Array} placeholder
         */
        setAddressPlaceholders({ dispatch }, placeholder) {
            dispatch('updatePlaceholder', placeholder);
            dispatch('updateAddressModel', addressEmptyObject);
        },
    };
}
