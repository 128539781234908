import { t } from 'i18next';
import fieldsTransformer from '../fields-transformer';
import { COUNTRIES, REGEX } from '../../core/constants';
import { includes, excludes } from '../forms-utils';

const commonFormNeededFields = [
    'country',
    'streetName',
    'house',
    'city',
    'state',
    'postcode',
];

export const stationFormNeededFields = [
    'country',
    'street',
    'house',
    'city',
    'postcode',
];
const stationPublicFormNeededFields = [
    'country',
    'street',
    'house',
    'city',
    'postcode',
];
const legalEntityFormNeededFields = [
    'country',
    'streetName',
    'streetName2',
    'house',
    'city',
    'state',
    'postcode',
];

const DIGITS_WORDS_SPACES_DASHES_UNICODE = /^[\p{N}\p{L}\-\s.]+$/u;
const WORDS_SPACES_DASHES_UNICODE = /^[\p{Alphabetic}\-\s]+$/u;

const fieldsRegistry = (countries) => [
    {
        label: t(['generic.entity.country', 'Country']),
        name: 'country',
        type: 'select',
        config: {
            options: [...countries],
            optionValue: 'code',
            placeholder: t(['generic.placeholder.selectOne', 'Select one']),
            id: 'country',
        },
        validation: {
            required: true,
        },
    },
    {
        label: t(['generic.label.streetName', 'Street name']),
        name: 'streetName',
        includeIf: excludes(COUNTRIES.US),
        type: 'text',
        config: {
            placeholder: t([
                'account.billingInformation.form.placeholder.streetName',
                'Street name, flat, suite, building, floor',
            ]),
            maxlength: 255,
            id: 'street-name',
        },
        validation: {
            pattern: DIGITS_WORDS_SPACES_DASHES_UNICODE,
            maxlength: 255,
            required: true,
        },
    },
    {
        label: t(['generic.label.streetAddress', 'Street address']),
        name: 'streetName',
        type: 'text',
        includeIf: includes(COUNTRIES.US),
        config: {
            maxlength: 255,
            id: 'street-name',
        },
        validation: {
            pattern: DIGITS_WORDS_SPACES_DASHES_UNICODE,
            maxlength: 255,
            required: true,
        },
    },
    {
        label: t(['generic.label.streetName', 'Street name']),
        name: 'street',
        includeIf: excludes(COUNTRIES.US),
        type: 'text',
        config: {
            placeholder: t([
                'account.billingInformation.form.placeholder.streetName',
                'Street name, flat, suite, building, floor',
            ]),
            maxlength: 255,
            id: 'street',
        },
        validation: {
            pattern: DIGITS_WORDS_SPACES_DASHES_UNICODE,
            maxlength: 255,
            required: true,
        },
    },
    {
        label: t(['generic.label.streetAddress', 'Street address']),
        name: 'street',
        type: 'text',
        includeIf: includes(COUNTRIES.US),
        config: {
            maxlength: 255,
            id: 'street',
        },
        validation: {
            pattern: DIGITS_WORDS_SPACES_DASHES_UNICODE,
            maxlength: 255,
            required: true,
        },
    },
    {
        name: 'streetName2',
        type: 'text',
        includeIf: excludes(COUNTRIES.US),
        config: {
            placeholder: t([
                'account.billingInformation.form.placeholder.streetName2',
                'P.O. box, house name, etc.',
            ]),
            maxlength: 255,
        },
        validation: {
            pattern: DIGITS_WORDS_SPACES_DASHES_UNICODE,
            maxlength: 255,
        },
    },
    {
        name: 'house',
        type: 'text',
        includeIf: excludes(COUNTRIES.US),
        label: t(['generic.label.house', 'House number']),
        labelHelp: t('generic.helpText.house'),
        config: {
            maxlength: 255,
            id: 'house',
        },
        validation: {
            pattern: DIGITS_WORDS_SPACES_DASHES_UNICODE,
            maxlength: 255,
            required: true,
        },
    },
    {
        name: 'house',
        type: 'text',
        includeIf: includes(COUNTRIES.US),
        label: t(['generic.label.apartment', 'Apt / Floor / Suite / No']),
        config: {
            maxlength: 255,
            id: 'house',
        },
        validation: {
            pattern: DIGITS_WORDS_SPACES_DASHES_UNICODE,
            maxlength: 255,
            required: true,
        },
    },
    {
        name: 'city',
        type: 'text',
        label: t(['generic.label.city', 'City']),
        config: {
            maxlength: 255,
            id: 'city',
        },
        validation: {
            pattern: WORDS_SPACES_DASHES_UNICODE,
            maxlength: 255,
            required: true,
        },
    },
    {
        name: 'state',
        type: 'text',
        labelHelp: t([
            'generic.placeholder.state',
            'Provide your 2-letter state code',
        ]),
        label: t(['generic.label.state', 'State']),
        includeIf: includes(COUNTRIES.US),
        cssClass: 'evo-uppercase',
        config: {
            maxlength: 2,
            minlength: 2,
            id: 'state',
        },
        validation: {
            required: true,
            maxlength: 2,
            minlength: 2,
            pattern: REGEX.ADDRESS_STATE,
        },
    },
    {
        name: 'postcode',
        type: 'text',
        label: t(['generic.label.postcode', 'ZIP/Postal code']),
        config: {
            maxlength: 255,
            id: 'postcode',
        },
        validation: {
            pattern: DIGITS_WORDS_SPACES_DASHES_UNICODE,
            maxlength: 255,
            required: true,
        },
    },
];

function getNeededFields(neededFields, countries) {
    return fieldsRegistry(countries).reduce(
        (formFields, field) =>
            neededFields.includes(field.name)
                ? formFields.concat(field)
                : formFields,
        []
    );
}

function getProcessedFormConfig(fields, options) {
    const {
        currentCountry,
        withTitle = true,
        name = 'address',
        description = '',
        title = t(['generic.label.address', 'Address']),
        ...rest
    } = options;

    return {
        title: withTitle ? title : null,
        name,
        description,
        fields: fieldsTransformer(fields, {
            filterCondition: currentCountry,
            ...rest,
        }),
    };
}

export function commonAddressForm({ countries = [], ...rest } = {}) {
    const fields = getNeededFields(commonFormNeededFields, countries);

    return getProcessedFormConfig(fields, rest);
}

export function stationAddressForm({ countries = [], ...rest } = {}) {
    const fields = getNeededFields(stationFormNeededFields, countries);

    return getProcessedFormConfig(fields, {
        ...rest,
        overrides: {
            house: { validation: { required: false } },
            // OCPI address validation rules
            postcode: { validation: { maxlength: 10 } },
            city: { validation: { maxlength: 45 } },
            street: { validation: { maxlength: 45 } },
        },
    });
}

export function stationPublicAddressForm({ countries = [], ...rest } = {}) {
    const fields = getNeededFields(stationPublicFormNeededFields, countries);

    return getProcessedFormConfig(fields, {
        ...rest,
        overrides: {
            house: { validation: { required: false } },
        },
    });
}

export function legalEntityAddressForm({ countries = [], ...rest } = {}) {
    const fields = getNeededFields(legalEntityFormNeededFields, countries);

    return getProcessedFormConfig(fields, rest);
}
