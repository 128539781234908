import { createRouter, createWebHistory } from 'vue-router';
import { t } from 'i18next';
import getRedirectRoute from './router.auth';

// local or prod (no adhoc uri segment)
export const baseUrl = `/${EVERON_DEPLOY_ENV ?? ''}`;

export function createEveronRouter() {
    return createRouter({
        history: createWebHistory(baseUrl),
        routes: [],
    });
}

function checkRoute(to) {
    // @TODO: THIS IF FOR MOBILE EMBEDDED WORKAROUND
    if (to.query.state) {
        window.location.href = to.query.state;
    }

    const nextRoute = getRedirectRoute(to, baseUrl);

    if (nextRoute.name !== to.name) {
        return nextRoute;
    }
}

export function setupEveronRouter(router) {
    router.beforeEach(checkRoute);

    router.afterEach((to) => {
        document.title = t(to.meta?.title) || 'Everon Portal';
    });
}
