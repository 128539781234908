import formatValuesInTimeZone from '../../utils/date/format-values-in-time-zone';
import searchMixinService from '../../services/mixins/search-mixin.service';
import sortingMixinService from '../../services/mixins/sorting-mixin.service';
import {
    transformTransactionDetails,
    downloadTransactions,
} from '../mixins/transaction-mixin.service.js';
import { hasPermission } from '../../utils/permissions';
import { getReimbursementSettings } from '../account/account.service.js';
import { getters as authGetters } from '../auth.service.js';
import { addCommunityChargingToProductsCharacteristics } from '../community-charging/community-charging.service.js';
import { isEmpty, omitByValue } from '../../utils/objects';
import apiClient from '../../core/api/api-client';
import { parseJSON } from '../../core/api/json-parser';

/**
 * Returns transformed product
 * @param {Object} product
 * @returns {Object}
 */
function transformProductCatalog(product) {
    const { plans, characteristics } = product;
    const defaultCurrency = plans?.[0]?.currency;
    const hasPublicTransactionFee = plans?.some(
        ({ publicTransactionFee }) =>
            publicTransactionFee && !isEmpty(publicTransactionFee)
    );
    const filteredCharacteristics = characteristics?.filter(
        (characteristic) => characteristic.enabled
    );
    const prices = plans?.map((plan) => plan.price).sort();
    const price = prices && prices[0];

    return {
        ...product,
        defaultCurrency,
        hasPublicTransactionFee,
        characteristics: filteredCharacteristics,
        price,
    };
}

/**
 * Verifies if card with a given contract id exists and if it has an owner
 * @param {string} contractId
 * @returns {Promise.<Object>}
 */
export function verifyCard(contractId) {
    return apiClient.get('/api/tokens/verification', { contractId });
}

/**
 * Returns paginated cards
 * @param {Object} [params]
 * @returns {Promise.<Array>}
 */
export function getCards(params = {}) {
    const cleanParams = omitByValue(params, [undefined, null, '']);
    const mergedParams = { page: 0, ...cleanParams };

    return apiClient.get('/api/tokens/list', mergedParams);
}

/**
 * Returns tokens for a given account id
 * @param {Object} params
 * @returns {Promise<Object>}
 */
export function getAccountTokens(params = {}) {
    const cleanParams = omitByValue(params, [undefined, null, '']);
    const mergedParams = {
        ...cleanParams,
        excludeAccountData: true,
    };

    return apiClient.get('/api/tokens/v1/tokens', mergedParams);
}

/**
 * Returns tokens count for a given account id
 * @param {Object} params
 * @returns {Promise<number>}
 */
export async function getAccountTokensCount(params) {
    const result = await getAccountTokens(params);

    return result?.totalElements || 0;
}

/**
 * Activates inactive card
 * @param {Object} requestData
 * @returns {Promise.<Object>}
 */
export function activateCard(requestData) {
    return apiClient.post('/api/tokens/activation', requestData);
}

/**
 * Activate a token via Patch
 * @param {Object} requestData token data
 * @param {Object} params request params
 * @returns {*}
 */
export function activateToken(requestData, params) {
    return apiClient.patch(
        `/api/tokens/v1/tokens:activation`,
        requestData,
        params,
        {
            'Content-Type': 'application/merge-patch+json',
        }
    );
}

/**
 * Activates inactive card on behalf of another user
 * @param {Object} requestData
 * @returns {Promise.<Object>}
 */
export function activateCardOnBehalf(requestData) {
    return apiClient.post('/api/tokens/activation-on-behalf', requestData);
}

/**
 * Activates pending card
 * @param {string} id
 * @param {Object} requestData
 * @returns {Promise.<Object>}
 */

export function confirmCardActivation(id, requestData) {
    return apiClient.patch(
        `/api/billing/subscriptions/cards/${id}/confirm-activation`,
        requestData,
        {},
        {
            'Content-Type': 'application/merge-patch+json',
        }
    );
}

/**
 * Imports cards
 * @param {Object} requestData
 * @returns {Promise.<Object>}
 */
export function importCard(requestData) {
    return apiClient.post('/api/tokens/import', requestData);
}

/**
 * Deletes inactive card with a given ID
 * @param {string} id
 * @returns {Promise.<Object>}
 */
export function deleteCard(id) {
    return apiClient.delete(`/api/tokens/${id}`);
}

/**
 * Returns a card by a given id
 * @param {string} cardId
 * @returns {Promise.<Object>}
 */
export function getCard(cardId) {
    const baseUrl = '/api/tokens/v1/tokens';

    return apiClient.get(`${baseUrl}/${cardId}`);
}

/**
 * Updates card
 * @param {string} cardId
 * @param {Object} requestData
 * @returns {Promise.<Object>}
 */
export function updateCard(cardId, requestData) {
    return apiClient.patch(
        `/api/tokens/${cardId}`,
        requestData,
        {},
        {
            'Content-Type': 'application/merge-patch+json',
        }
    );
}

/**
 * Returns an object of all closed transactions for a given card, sorted by year and month
 * @param {string} cardId
 * @param {Object} range
 * @param {number} limit
 * @param {string|null} location
 * @returns {Promise.<Object>}
 */
export async function getClosedTransactions(
    cardId,
    range,
    limit = 100,
    location = null
) {
    const params = { status: 'COMPLETED', limit };

    if (location) {
        params.location = location;
    }

    const response = await apiClient.goFetch(
        `/api/transactions/cards/${cardId}`,
        {
            params: {
                ...params,
                ...formatValuesInTimeZone(range, 'UTC'),
            },
        }
    );

    const transactions = await parseJSON(response);
    let cursorMatch;

    if (response.headers.has('Link')) {
        const linkHeader = response.headers.get('Link');

        cursorMatch = linkHeader.split('location=').pop();

        if (!cursorMatch.includes('next')) {
            cursorMatch = null;
        }
    }

    return {
        items: transactions.map(transformTransactionDetails),
        responseHeaderLink: cursorMatch
            ? cursorMatch.replace('>; rel="next"', '')
            : '',
    };
}

/**
 * Checks if subscription can be set on a card, 200 if true, 412 if a subscription was set in the last 24h.
 * @param {string} cardId
 * @returns {Promise}
 */
export function isUpdatable(cardId) {
    return apiClient
        .head(`/api/billing/subscriptions/cards/${cardId}/updatable`)
        .then((response) => response.status === 200)
        .catch(() => false);
}

/**
 * Returns a list of card products where each product contains a list of plans
 * @param {string} country
 * @param {string} accountId
 * @returns {Promise.<Array>}
 */
export function getProductCatalog(country, accountId) {
    return apiClient
        .get('/api/billing/product-catalog/cards', { country, accountId })
        .then((products) => products.map(transformProductCatalog))
        .then((products) =>
            addCommunityChargingToProductsCharacteristics(products, accountId)
        );
}

/**
 * Returns a list of card products available for card plan update
 * @param {Object} subscription
 * @param {Object} card
 * @returns {Promise.<Array>}
 */
export function getAvailableProductCatalog(subscription, card) {
    const {
        product: {
            country: { code },
        },
    } = subscription;
    const { accountId, status, deactivationDate } = card;

    if (
        status === 'active' &&
        !deactivationDate &&
        hasPermission('CARD:UPDATE')
    ) {
        return getProductCatalog(code, accountId);
    } else {
        return Promise.resolve([]);
    }
}

export function downloadCardTransactions(...args) {
    return downloadTransactions(...args, 'cards');
}

/**
 * Loads transactions and reimbursement for a given card.
 * Defines if user allowed to see reimbursable.
 * @param {string} startDate
 * @param {string} endDate
 * @param {Object} card
 * @returns {Promise}
 */
export async function setupTransactionsData(startDate, endDate, card) {
    const isRoleAllowedToSeeReimbursable = !authGetters.isTenantLevelUser;
    const assetOwnerId = card.accountId || 'me';

    const transactions = await getClosedTransactions(card.id, {
        startDate,
        endDate,
    });
    const reimbursement = isRoleAllowedToSeeReimbursable
        ? await getReimbursementSettings(assetOwnerId)
        : null;

    return {
        transactions,
        card,
        reimbursement,
        isRoleAllowedToSeeReimbursable,
    };
}

/**
 * Update public charging of a token via Patch
 * @param {string} id of the token
 * @param {boolean|null} publicCharging enable/disable public charging
 * @param {boolean|null} reimbursement enable/disable driver reimbursement
 * @returns {Promise}
 */
export function updatePublicChargingReimbursement(
    id,
    publicCharging,
    reimbursement
) {
    // Omit values in the payload if they don't need to be updated
    const tokenUpdate = {
        ...(reimbursement !== null
            ? { reimbursement: Boolean(reimbursement) }
            : {}),
        ...(publicCharging !== null
            ? { publicCharging: Boolean(publicCharging) }
            : {}),
    };

    return apiClient.patch(
        `/api/tokens/v1/tokens/${id}`,
        {
            tokenUpdate,
            tokenUpdateType: 'update',
        },
        {},
        {
            'Content-Type': 'application/merge-patch+json',
        }
    );
}

export default {
    ...searchMixinService,
    ...sortingMixinService,
    verifyCard,
    getCards,
    getAccountTokens,
    getAccountTokensCount,
    activateCard,
    activateToken,
    activateCardOnBehalf,
    confirmCardActivation,
    importCard,
    deleteCard,
    getCard,
    updateCard,
    getClosedTransactions,
    isUpdatable,
    getProductCatalog,
    getAvailableProductCatalog,
    downloadCardTransactions,
    setupTransactionsData,
    updatePublicChargingReimbursement,
};
