export default {
    /**
     * Updates state with patch object.
     * @param {Object} state
     * @param {Object} data
     */
    updateWithObject(state, data = {}) {
        Object.entries(data).forEach(([key, value]) => {
            state[key] = value;
        });
    },

    /**
     * Updates state with normalised address object and creates a model as a copy of it to be used in the form.
     * @param {Object} state
     * @param {Object} address
     */
    updateAddress(state, address) {
        state.address = { ...address };
        state.addressModel = { ...address };
    },

    /**
     * Updates state with normalised address object and creates a model as a copy of it to be used in the form.
     * @param {Object} state
     * @param {Object} geocoordinates
     */
    updateGeocoordinates(state, geocoordinates) {
        state.geocoordinates = { ...geocoordinates };
        state.geocoordinatesModel = { ...geocoordinates };
    },

    /**
     * Updates address model in state.
     * @param {Object} state
     * @param {Object} address
     */
    updateAddressModel(state, address) {
        state.addressModel = { ...address };
    },

    /**
     * Updates geocoordinates model in state.
     * @param {Object} state
     * @param {Object} geocoordinates
     */
    updateGeocoordinatesModel(state, geocoordinates) {
        state.geocoordinatesModel = { ...geocoordinates };
    },
};
