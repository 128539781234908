import { poll, TimeoutError } from '../../../../utils/async';
import { handleUserError } from '../../../../utils/handle-generic-notifications';
import { evseHasTransaction } from '../utils';
import { getOpenTransactions } from '../../../../services/api/stations/v1/transactions.api';

const timeout = 60_000; // 1 minute
const timeoutNotification = 'generic.error.longerThanUsualRefresh';
const failedConnectorStatusNotification =
    'stations.components.connectorsControl.fetchConnectorStatusFailed';

export default function getReadTransactionActions() {
    return {
        async fetchOpenTransactions({ state, commit }) {
            try {
                const openTransactions = await getOpenTransactions(
                    state.stationId
                );

                commit('updateOpenTransactionsData', openTransactions);
            } catch (error) {
                if (!error.status) {
                    throw error;
                }

                handleUserError(
                    'stations.components.connectorsControl.fetchOpenTransactionsFailed'
                );
            }
        },

        async waitUntilTransactionAppears({ dispatch }, connector) {
            await dispatch('pollOpenTransactionsUntil', (openTransactions) =>
                evseHasTransaction(openTransactions, connector)
            );
        },

        async waitUntilTransactionDisappears({ dispatch }, transactionId) {
            await dispatch(
                'pollOpenTransactionsUntil',
                (openTransactions) =>
                    !openTransactions.some(
                        (transaction) => transaction.id === transactionId
                    )
            );
        },

        async pollOpenTransactionsUntil({ state, commit }, predicate) {
            async function getStationOpenTransactions() {
                const openTransactions = await getOpenTransactions(
                    state.stationId
                );

                commit('updateOpenTransactionsData', openTransactions);

                return openTransactions;
            }

            try {
                await poll(getStationOpenTransactions, {
                    until: predicate,
                    interval: (i) => (i + 1) * 2 * 1000,
                    timeout,
                });
            } catch (error) {
                if (!error.status) {
                    throw error;
                }

                const timedOut = error instanceof TimeoutError;

                const messageKey = timedOut
                    ? timeoutNotification
                    : failedConnectorStatusNotification;

                handleUserError(messageKey);
            }
        },
    };
}
