import { COUNTRIES } from '../core/constants';
/**
 * Returns the address with a country code instead of a country object
 * @param {Object} address
 * @returns {Object}
 */
export function useCountryCode(address) {
    return { ...address, country: address.country?.code };
}

/**
 * Returns the address with a country object instead of a country code
 * @param {Object} address
 * @param {Array} countries
 * @returns {Object}
 */
export function useCountryObject(address, countries) {
    const country = countries.find(({ code }) => code === address.country);

    return { ...address, country };
}
export function countryWithState(country) {
    return [COUNTRIES.US].includes(country);
}

export function transformAddressResponse(address = {}) {
    const {
        country,
        state = null,
        administrativeArea = null,
        ...addressRest
    } = address;

    return {
        ...addressRest,
        state: state || administrativeArea,
        country: country?.code || null,
    };
}

export function transformAddressModel(address = {}, countries = []) {
    const { country, ...addressRest } = address;

    const transformedModel = {
        ...addressRest,
        country: { ...countries.find(({ code }) => code === country) },
    };

    if (typeof transformedModel.state === 'string') {
        transformedModel.state = transformedModel.state.toUpperCase();
    }

    return transformedModel;
}
