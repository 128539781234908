<template>
    <modal-component
        v-if="modal.isOpen"
        v-bind="modal.props"
        @modal-close="closeModal"
        @modal-event="handleModalEvent"
    >
    </modal-component>
</template>

<script setup>
import { computed, defineAsyncComponent } from 'vue';
import {
    computedModal,
    closeModal,
    handleModalEvent,
} from '../../services/modal/modal.service.js';
import Spinner from '../../components/spinner';

const modal = computed(() => computedModal());

const ModalComponent = defineAsyncComponent({
    loader: () => modal.value.component,
    loadingComponent: Spinner,
});
</script>
