import allModules from '../modules';

export default (router) =>
    Promise.all(allModules.map((path) => import(`../modules/${path}`)))
        .then((modules) => modules.map((mod) => mod.default))
        .then((modules) =>
            modules.forEach(({ name, routes }) => {
                if (routes) {
                    routes?.items.forEach((route) => {
                        router.addRoute(route);
                    });
                }
            })
        )
        .catch((err) => console.error(err));
