import state from './state';
import mutations from './mutations';
import getters from './getters';
import getActions from './actions';

export default {
    namespaced: true,
    state: () => ({ ...state }),
    mutations,
    getters,
    actions: getActions(),
};
